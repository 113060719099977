/* FONT SMOOTHING */
.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

/* FONT STYLE */
.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

/* LETTER SPACING */
.tracking-tighter {
  letter-spacing: -0.05em;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

/* LINE HEIGHT */
.leading-3 {
  line-height: 0.75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

/* TEXT ALIGN */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* TEXT COLOR */
.text-transparent {
  color: transparent;
}

.text-current {
  color: currentColor;
}

.text-black {
  color: $color-black;
}

.text-white {
  color: $color-gray-1;
}

/* TRANSFORM */
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.word-break {
  word-break: break-all;
}

.text-link {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    color: $color-primary-hover;
    text-decoration: none;
  }
}
