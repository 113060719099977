@import "../core/colors.scss";

.dialog-order {
  width: 95vw !important;

  .p-dialog-title {
    width: 100% !important;
    font-size: 22px !important;
    line-height: 32px;
    font-weight: 600;
  }

  .p-dialog-header {
    width: 100% !important;
    color: $color-gray-1 !important;
  }

  &.dialog-products {
    .p-dialog-header .p-dialog-title {
      font-size: 22px !important;
      font-weight: 600;
      line-height: 32px;
    }
  }

  &.dialog-confirmation {
    width: 500px !important;
    text-align: -webkit-center;
  }
}

.dialog-transactions {
  width: 70vw !important;

  .p-dialog-title {
    width: 100% !important;
    font-size: 30px !important;
  }

  .p-dialog-header {
    width: 100% !important;
    color: $color-white !important;
  }

  &.dialog-products {
    .p-dialog-header .p-dialog-title {
      font-size: 22px !important;
      font-weight: 700;
    }
  }

  &.dialog-confirmation {
    width: 35vw !important;
    text-align: -webkit-center;
  }
}

.dialog-product {
  .p-dialog-header .p-dialog-title {
    font-size: 24px !important;
    word-break: break-word !important;
  }
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 23px !important;
  height: 23px !important;

  .p-dialog-header-close-icon {
    font-size: 11px !important;
  }
}

.p-dialog-header {
  position: relative;

  .p-dialog-header-icons {
    position: absolute !important;
    top: 16px !important;
    right: 16px !important;
    bottom: auto !important;
    left: auto !important;

    .p-dialog-header-icon {
      color: $color-gray-2 !important;

      .pi.pi-times {
        &:before {
          display: none;
        }

        &:after {
          content: url("../../assets/icon/circle-xmark-light.svg");
          position: absolute;
          right: 0;
          top: 0;
          width: 23px;
          height: 23px;
        }
      }
    }
  }
}

.p-dialog-header .p-dialog-header-icons .p-dialog-header-icon {
  border: none;
}

.p-dialog-header-icon.p-dialog-header-close {
  color: $color-gray-2 !important;

  .pi.pi-times {
    &:before {
      display: none;
    }

    &:after {
      content: url("../../assets/icon/circle-xmark-light.svg");
      position: absolute;
      right: 0;
      top: 0;
      width: 23px;
      height: 23px;
    }
  }
}

.ng-start-inserted {
  width: 100% !important;
}

.dialogLoading {
  .p-dialog-content {
    height: 100% !important;
    text-align-last: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.p-dialog {
  border-radius: 18px !important;
}

.p-dialog .p-dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  border-bottom-right-radius: 18px !important;
  border-bottom-left-radius: 18px !important;
}

.p-dialog .p-dialog-header {
  border-top-right-radius: 18px !important;
  border-top-left-radius: 18px !important;
}

div.p-dialog .p-dialog-header {
  background: $color-gray-5;
  border-color: $color-gray-5;
  color: $color-gray-1;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 32px;
  padding-bottom: 0;
}

div.p-dialog.store-details-admin-dialog {
  .p-dialog-content {
    & > :first-child {
      overflow-y: auto;
    }
  }
}

div.p-dialog .p-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-gray-5;
  color: $color-gray-1;
  padding: 10px 24px 0;


  .p-confirm-dialog-message span.text-primary {
    font-weight: 600 !important;
    color: $color-primary !important;
  }

  form {
    width: 100% !important;
    padding-top: 10px;
  }
}

div.p-dialog .p-dialog-footer {
  background: $color-gray-5;
  color: $color-gray-1;
  padding: 40px 24px 24px;
}

.p-dialog .p-dialog-title {
  font-size: 22px !important;
  font-weight: 600;
  word-break: break-all;
}

.p-dialog,
.p-dialog-mask.p-component-overlay {
  backdrop-filter: blur(10px);
  z-index: 1002 !important;
}

.p-dialog .p-dialog-footer button.btn {
  width: 100%;
}

.p-dialog .p-confirm-dialog-message .subheader {
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 0;
  color: $color-gray-3;
}

.content-style-dialog {
  width: 100%;

  .p-dialog-content {
    border-bottom-left-radius: 18px !important;
    border-bottom-right-radius: 18px !important;
    padding-bottom: 20px !important;
  }

  .p-dialog-header {
    padding-top: 20px !important;
    padding-bottom: 10px !important;
  }
}

.store-details-admin-dialog {
  background: $color-gray-55 !important;

  .p-dialog-header {
    position: relative;
    background: $color-gray-55 !important;
  }

  .p-dialog-content {
    overflow: hidden;
    background: $color-gray-55 !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}

.store-confirmation-available-app {
  background: $color-gray-5 !important;

  .p-dialog-header {
    position: relative;
    background: $color-gray-5 !important;
  }

  .p-dialog-content {
    overflow: hidden;
    background: $color-gray-5 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.statics-admin-dialog {
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  max-width: 1145px;

  .p-dialog-header {
    position: relative;
    background: $color-gray-55 !important;
  }

  .p-dialog-content {
    background: $color-gray-55 !important;
  }
}

.global-statistics-dialog {
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  max-width: 1145px;

  app-transactions-dialog {
    width: 100%;
  }


  .p-dialog-header {
    position: relative;
    background: $color-gray-55 !important;
  }

  .p-dialog-content {
    background: $color-gray-55 !important;
  }
}

.config-dialog {
  app-payments-config-dialog {
    width: 100%;
  }

  .p-dialog-header {
    position: relative;
    background: $color-gray-5 !important;
  }

  .p-dialog-content {
    background: $color-gray-5 !important;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.config-dialog__mobbex {
  app-payments-mobbex-config-dialog {
    width: 100%;
  }

  .p-dialog-header-icons {
    z-index: 2 !important;
  }

  .p-dialog-content {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}

.config-dialog__mercadopago {
  app-payments-mercadopago-config-dialog {
    width: 100%;
  }

  .p-dialog-header-icons {
    z-index: 2 !important;
  }

  .p-dialog-content {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}

.table-guest-dialog {
  .p-dialog-header {
    position: relative;
  }

  .p-dialog-content {
    border-bottom-left-radius: 18px !important;
    border-bottom-right-radius: 18px !important;
    padding-bottom: 20px !important;
    height: 100%;
  }

  .p-dialog-footer {
    background: $color-gray-5;
    border-color: $color-gray-5;
    color: $color-gray-1;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }
}

.config-dialog__edit-email, .config-dialog__edit-cuit {
  app-change-email, app-change-cuit {
    width: 100%;
  }

  .p-dialog-header {
    position: relative;
    background: #2B2B2B !important;
    padding-bottom: 24px !important;
    padding-top: 24px !important;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #030303;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #525252;
    }

    .p-dialog-header-icons {
      top: 24px !important;
    }
  }

  .p-dialog-title {
    font-family: 'Poppins', sans-serif;
    font-size: 16px !important;
    line-height: 24px;
    color: $color-gray-1;
    font-weight: 600;
  }

  .p-dialog-content {
    overflow: hidden !important;
    background: $color-gray-5 !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}

.menu-admin-dialog {
  app-payments-config-dialog {
    width: 100%;
  }

  .p-dialog-header {
    position: relative;
    background: $color-gray-55 !important;
  }

  .p-dialog-content {
    overflow: hidden !important;
    background: $color-gray-55 !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}

.config-dialog__pago-uno {
  .p-dialog-header {
    position: relative;
    background: $color-gray-5 !important;
  }

  .p-dialog-header .p-dialog-header-icons {
    z-index: 4 !important;
  }

  .p-dialog-content {
    overflow: hidden !important;
    background: $color-gray-5 !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;

    app-payments-config-dialog, app-payments-pagouno-config-dialog {
      width: 100%;
    }
  }
}

.config-dialog__verify-tenant {
  .p-dialog-header {
    position: relative;
    background: $color-gray-5 !important;
  }

  .p-dialog-header .p-dialog-header-icons {
    z-index: 4 !important;
  }

  .p-dialog-content {
    overflow: visible !important;
    background: $color-gray-5 !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
}

.config-dialog__transactions {
  .p-dialog-header {
    position: relative;
    background: $color-gray-55 !important;
  }

  .p-dialog-content {
    overflow: hidden !important;
    background: $color-gray-55 !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;

    app-transaction-dialog {
      width: 100%;
    }
  }
}

.dialog-image {
  .p-dialog-content {
    padding: 24px !important;
    border-bottom-left-radius: 18px !important;
    border-bottom-right-radius: 18px !important;
    text-align: center !important;

    img {
      max-height: 498px;
      border-radius: 8px;
    }
  }
}

//dynamic dialog

p-dynamicdialog::ng-deep .p-component-overlay {
  position: fixed !important;
  z-index: 9999 !important;
}

.p-dialog-content .icon-warning {
  margin-bottom: 25px;
}

.category-form-container {
  background: $color-gray-5;
  border-radius: 12px;

  .p-dialog-header {
    background: $color-gray-5 !important;
  }

  .p-dialog-content {
    background: $color-gray-5 !important;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }

  .title-float {
    background-color: $color-gray-5 !important;
  }
}

p-confirmdialog::ng-deep .p-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .blackid-icons {
    width: 72px;
    height: 74px;
    margin-bottom: 10px;
  }
}

p-confirmdialog::ng-deep {
  max-width: 400px !important;
}

.dialog-success-refund {
  .p-dialog-content {
    overflow: hidden !important;
  }
}
