.rounded {
  border-radius: 0.25rem; 
}
.rounded-md	{
  border-radius: 0.375rem;
}
.rounded-lg	{
  border-radius: 0.5rem;
}
.rounded-xl	{
  border-radius: 0.75rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-full {
  border-radius: 9999px !important;
}

/* BORDER WIDTH */
.border {
  border-width: 1px;
}
.border-2	{
  border-width: 2px;
}

/* BORDER STYLE */
.border-solid	{
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-dotted {
  border-style: dotted;
}
.border-none {
  border-style: none;
}