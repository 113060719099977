.cursor-pointer {
  cursor: pointer;
}

.box__total {
  padding: 5px 12px;
  border-radius: 8px;
  background-color: $color-gray-5;
  font-size: 16px;
  color: $color-gray-1;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.ellipsis-one-line {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ellipsis-two-lines {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
