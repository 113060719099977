@import "colors.scss";

.icon-3xs:before {
  font-size: 12px;
}
.icon-2xs:before {
  font-size: 14px;
}
.icon-xs:before {
  font-size: 16px;
}
.icon-sm:before {
  font-size: 20px;
}
.icon-md:before {
  font-size: 25px;
}
.icon-lg:before {
  font-size: 30px;
}
.icon-2xl:before {
  font-size: 35px;
}
.icon-3xl:before {
  font-size: 40px;
}
.icon-4xl:before {
  font-size: 72px;
}

.icon-primary:before {
  color: $color-primary;
}
.icon-black:before {
  color: $color-black;
}
.icon-white:before {
  color: $color-gray-1;
}
.icon-red:before {
  color: $color-error;
}
.icon-yellow:before {
  color: $color-yellow;
}
.icon-green:before {
  color: $color-success;
}
.icon-gray:before {
  color: $color-gray-4;
}
.icon-orange:before {
  color: $color-warning;
}
