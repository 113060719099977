.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 0px 7px 4px rgb(0 0 0 / 0.3), 0 0px 4px -2px rgb(0 0 0 / 0.3);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0), var(--tw-shadow);
}
