/* CONTAINER */
.container {
  width: 100%;
}

.body {
  width: 100%;
  min-height: calc(100vh - 60px);
  padding: 120px 20px 80px 38px;
  background-color: $color-gray-55;
  transition: all 200ms ease-in-out;

  &.lateral-open {
    padding: 120px 40px 80px 280px;
    transition: all 250ms ease-in-out;
  }
}

@media only screen and (min-width: 640px) {
  .container {
    max-width: 640px;
  }

  .body {
    padding: 120px 16px 130px 28px;
  }
}
@media only screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media only screen and (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media only screen and (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media only screen and (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.table-row {
  display: table-row;
}

.relative {
  position: relative;
}
.sticky {
  position: sticky;
}

/* VISIBILITY */
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}

/* Z-INDEX */
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-auto {
  z-index: auto;
}

.mx-col {
  max-width: 50%;
  min-width: 50%;
}

.mx-col-35 {
  max-width: 35%;
  min-width: 35%;
}

.mx-col-40 {
  max-width: 40%;
  min-width: 40%;
}

.mx-col-60 {
  max-width: 60%;
  min-width: 60%;
}

.mx-col-65 {
  max-width: 65%;
  min-width: 65%;
}

.mx-col-30 {
  max-width: 30%;
  min-width: 30%;
}

.mx-col-70 {
  max-width: 70%;
  min-width: 70%;
}

.mx-col-20 {
  max-width: 20%;
  min-width: 20%;
}

.mx-col-80 {
  max-width: 80%;
  min-width: 80%;
}

.mx-col-10 {
  max-width: 10%;
  min-width: 10%;
}

.mx-col-90 {
  max-width: 90%;
  min-width: 90%;
}

.mx-col-15 {
  max-width: 15%;
  min-width: 15%;
}

.mx-col-85 {
  max-width: 85%;
  min-width: 85%;
}

.mx-col-55 {
  max-width: 55%;
  min-width: 55%;
}

.mx-col-50 {
  max-width: 50%;
  min-width: 50%;
}

.mx-col-25 {
  max-width: 25%;
  min-width: 25%;
}
