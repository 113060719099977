@import "../core/colors.scss";

.p-menuitem-text {
  font-weight: normal;
  color: $color-gray-3;
  font-size: 14px;
}

/* Custom Header */
p-tabMenu::ng-deep .p-tabmenu {
  display: flex;
  // margin-bottom: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
}

p-tabMenu::ng-deep .p-tabmenu .p-tabmenu-nav {
  border-radius: 10px;
}

p-tabMenu::ng-deep .p-tabmenu .p-tabmenu-nav {
  background: $color-gray-6;
  border: 0;
  padding: 5px 4px;
  margin-bottom: 8px;
}

p-tabMenu::ng-deep
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem.ng-star-inserted
  .p-menuitem-link {
  background: $color-gray-6;
  border-radius: 8px;
  border: 0;
  padding: 10px 20px;
  margin: 0 2px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  transition: all 200ms ease-in-out;
  min-width: 155px;

  &:hover {
    background: $color-gray-5;
    transition: all 200ms ease-in-out;
  }
}

p-tabMenu::ng-deep
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem.ng-star-inserted
  .p-menuitem-link.p-menuitem-link-active {
  background: $color-primary;
  font-weight: 600;
  font-size: 14px;
  // width: 155px;
}

p-tabMenu::ng-deep
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem.ng-star-inserted
  .p-menuitem-link.p-menuitem-link-active
  .p-menuitem-text {
  color: $color-gray-6;
  font-weight: 600;
}

p-tabMenu::ng-deep
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem.ng-star-inserted
  .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}
