.field-checkbox {
  margin-bottom: 24px;

  .p-checkbox-label {
    color: $color-gray-3;
    margin-bottom: 0;
  }
}

.p-checkbox.p-component {
  .p-checkbox-box {
    background: transparent;
    border-radius: 2px;
    border: 1px solid $color-gray-3;

    &.p-focus {
      border: 1px solid $color-primary !important;
      box-shadow: none !important;
    }

    &.p-highlight {
      background: $color-primary;
      border: 1px solid $color-primary;

      &.p-focus {
        border: 1px solid $color-primary;
      }

      .p-checkbox-icon.pi.pi-check {
        color: $color-gray-5;
      }
    }

    &:hover {
      border: 1px solid $color-gray-4;
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box.p-highlight:hover {
      border: 1px solid $color-primary;
      background-color: $color-primary;
    }

    .p-checkbox-box.p-highlight:focus {
      border: 1px solid $color-primary;
    }

    .p-checkbox-box:hover {
      border: 1px solid $color-primary;
    }

    .p-checkbox-box:focus {
      border: 1px solid $color-primary;
    }
  }

  &.p-checkbox-focused {
    .p-checkbox-box {
      border: 1px solid $color-primary;
    }
  }
}

.checkPlatform {
  .p-checkbox-label {
    color: $color-gray-1 !important;
    margin-left: 16px !important;
  }
}

.p-checkbox-label {
  color: $color-gray-1 !important;
  margin-left: 16px !important;
}

.field-checkbox > .p-checkbox.p-component > .p-checkbox-box.p-highlight:hover {
  background: $color-primary;
}

.field-checkbox-rounded {
  margin-bottom: 24px;
  margin-top: 12px;

  .p-checkbox-box {
    background: transparent;
    border-radius: 50% !important;
  }
}

.checkbox-filter-sales {
  margin-bottom: 0;
  margin-top: 0;
  align-items: start;

  .p-checkbox-box {
    height: 18px !important;
    width: 18px !important;
  }
}
