
p-dropdown {
  .p-paginator-rpp-options.p-dropdown.p-component {
    position: relative;
    max-width: 64px;
    background: #101010;
    border: 0.5px solid #B8B8B8;
    border-radius: 4px;
    margin: 0;

    &:not(.p-disabled):hover {
      border: 1px solid $color-gray-3;
      box-shadow: none;
    }

    &:not(.p-disabled).p-focus {
      border: 1px solid $color-gray-3;
      box-shadow: none;
    }

    .p-dropdown-trigger {
      width: 20px;
    }

    .p-dropdown-label {
      font-family: "Inter";
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #F3F3F3;
      padding: 10px 8px 8px 8px;
      margin: 0;
    }

    .pi.pi-chevron-down {
      &:before {
        display: none;
      }

      &:after {
        content: url('../../assets/icon/chevron-down.png');
        position: absolute;
        top: 25%;
        right: 20%;
        width: 10px;
        height: 16px;
      }
    }
  }
}

.month-dropdown {
  width: 240px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 7px;

}

.month-dropdown, .tenant-dropdown {
  .p-dropdown {
    border: none !important;

    .p-dropdown-label {
      padding: 8px 16px;
      color: $color-gray-1 !important;
      font-weight: 300;
      line-height: 16px !important;
    }
  }
}
