@import "../core/colors.scss";
@import "../helpers/mixins.scss";

// Table header Pedidos/Pedidos mesa
.table-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px 24px !important;

  &.first-line {
    border-bottom: 0.5px solid $color-gray-8;
  }

  @include for-breakpoint('ipad') {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 16px;

    @include for-breakpoint('ipad') {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0;
    }

    .search-container {
      margin-top: 8px;

      @include for-breakpoint('ipad') {
        margin-top: 0;
      }
    }
  }
}

p-dropdown::ng-deep .p-dropdown.p-component {
  border: 0 !important;
  background-color: $color-gray-5 !important;
  width: 200px;
  margin-right: 15px;
}
