.bg-white {
  background-color: $color-gray-1;
}

.bg-gray-800 {
  background-color: $color-gray-6;
}

.bg-gray-55 {
  background-color: $color-gray-55 !important;
}
