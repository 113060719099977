.title-config {
  margin: 0;
  display: inline-block;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -0.2px;
  color: #f4f4f4;
}

.border-top-gray {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #616161;
}

.border-left-gray {
  border-left: 1px solid #616161;
  display: flex;
  align-items: center;
}

.p-description-gray {
  color: #bbbbbb;
  line-height: 1.3;
}

.container-logo {
  //padding: 20px 20px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  width: 60px;
  height: 60px;
  border-radius: 12px;
}

.header-img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.switch-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.switch-container {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
  max-width: 110px;
  padding: 5px 10px 0;
  border-radius: 5px;
  &.notifications {
    padding: 0;
  }
}

.switch-value {
  margin-left: 10px;
  margin-bottom: 4px;
}

.label-switch {
  margin-left: 14px;
  margin-bottom: 0 !important;
  font-weight: 600;
  color: #d6d6d6;
}

.buttons-bottom {
  margin-top: 40px;
}
