@import "../core/colors.scss";

.p-radiobutton.p-component
  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: $color-primary;
  background: $color-primary;
}

.p-radiobutton.p-component .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none;
}
