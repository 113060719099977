.bg-gradient {
  background: rgb(55, 55, 55);
  background: linear-gradient(
    90deg,
    rgba(55, 55, 55, 1) 0%,
    rgba(55, 55, 55, 1) 45%,
    rgb(28, 28, 28) 100%
  );
}

.bg-gray-dark {
  background-color: #242424 !important;
  background-image: none !important;
}
