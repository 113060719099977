@import "../core/colors.scss";

.p-button-delete {
  background: $color-gray-6 !important;
  border: 1px solid $color-gray-1 !important;
  color: $color-gray-1 !important;
}

.p-button-help {
  background: $color-gray-6 !important;
  border: 1px solid $color-gray-1 !important;
  color: $color-gray-1 !important;
}

.p-button-increment {
  background: $color-gray-5 !important;
  border: 1px solid $color-gray-5 !important;
  color: $color-gray-3 !important;
  border-radius: 5px !important;

  height: 46px !important;
  width: 46px !important;
}

.p-button-increment-2 {
  background: $color-gray-55 !important;
  border: 1px solid $color-gray-55 !important;
  color: $color-gray-3 !important;
  border-radius: 5px !important;
  margin-left: 1rem !important;

  height: 46px !important;
  width: 46px !important;
}

.p-button-decrement {
  background: $color-gray-5 !important;
  border: 1px solid $color-gray-5 !important;
  color: $color-gray-3 !important;
  border-radius: 5px !important;

  height: 46px !important;
  width: 46px !important;
}

.p-button-decrement-2 {
  background: $color-gray-55 !important;
  border: 1px solid $color-gray-55 !important;
  color: $color-gray-3 !important;
  border-radius: 5px !important;
  margin-right: 1rem !important;

  height: 46px !important;
  width: 46px !important;
}

.p-button.p-button-danger {
  border-radius: 2rem !important;
}

.p-button:focus {
  box-shadow: none;
}

.p-button.p-button-icon-only {
  background: transparent;
  border: none;
  border-radius: 30px;
  padding: 5px;
  width: 30px;
  height: 30px;

  &:hover {
    opacity: 0.7;
  }
}

.btn__link {
  background-color: transparent;
  outline: none;
  border: none;
  color: $color-primary !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  text-decoration: none;

  &:hover,
  &:active {
    color: $color-primary-pressed !important;
  }

  &:focus {
    outline: none;
  }

  .active {
    color: $color-primary-pressed !important;
  }
}

.btn-expand.p-button.p-button-icon-only {
  background-color: $color-gray-6;
  border-radius: 4px;
}

.btn__icon {
  background-color: transparent;
  outline: none;
  border: none;
  color: $color-primary;

  &:hover {
    background-color: transparent;
    color: $color-primary-hover;
  }
}

/* Button */
.p-button.p-component.btn {
  padding: 15px 32px;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  .p-button-label {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 16px;
    color: $color-gray-6;
    flex: none;
    transition: all 250ms ease-in-out;
  }

  &.btn__primary {
    background: $color-primary;
    border-color: $color-primary;
    transition: all 300ms ease-in;

    &:hover {
      transition: all 250ms ease-in;
      background: $color-primary-hover;
      border-color: $color-primary-hover;

      .p-button-label {
        transition: all 250ms ease-in;
      }
    }

    &:focus {
      transition: all 250ms ease-in;
      background: $color-primary-pressed;
      border-color: $color-primary-pressed;

      .p-button-label {
        transition: all 250ms ease-in;
      }
    }

    &:disabled {
      background: $color-primary-disabled;
      border-color: $color-primary-disabled;
    }

    .p-button-label {
      font-weight: 600;
      transition: all 300ms ease-in-out;
    }

    .p-button-icon {
      color: $color-gray-6;
    }

    &.btn__primary--booking {
      padding: 8px 36px;
    }

    &.btn__primary--bold {
      .p-button-label {
        font-weight: 600;
      }
    }
  }

  &.btn__secondary {
    background: none;
    color: $color-primary;
    border: 1px solid $color-primary;
    transition: all 300ms ease-in;

    &:hover {
      transition: all 250ms ease-in;
      color: $color-primary-hover;
      background: $color-primary-secondary-hover;
      border-color: $color-primary-hover;

      .p-button-label {
        transition: all 250ms ease-in;
        color: $color-primary-pressed;
      }

      .p-button-icon {
        transition: all 250ms ease-in;
        color: $color-primary-pressed;
      }
    }

    &:focus {
      transition: all 250ms ease-in;
      background: $color-primary-secondary-pressed;
      border-color: $color-primary-pressed;

      .p-button-label {
        transition: all 250ms ease-in;
        color: $color-primary-pressed;
      }

      .p-button-icon {
        transition: all 250ms ease-in;
        color: $color-primary-pressed;
      }
    }

    &:disabled {
      transition: all 250ms ease-in;
      border-color: $color-primary-disabled;

      .p-button-label {
        transition: all 250ms ease-in;
        color: $color-primary-disabled;
      }

      .p-button-icon {
        transition: all 250ms ease-in;
        color: $color-primary-disabled;
      }
    }

    .p-button-label {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 16px;
      color: $color-primary;
      flex: none;
      transition: all 250ms ease-in-out;
    }

    .p-button-icon {
      color: $color-primary;
    }

    &.btn__secondary--red {
      background: none !important;
      color: $color-error;
      border: 1px solid $color-error;
      transition: all 300ms ease-in;

      &:hover {
        transition: all 250ms ease-in;
        background: $color-error-pressed;
        border-color: $color-error-hover;

        .p-button-label {
          transition: all 250ms ease-in;
          color: $color-error-hover;
        }

        .p-button-icon {
          transition: all 250ms ease-in;
          color: $color-error-hover;
        }
      }

      &:focus {
        transition: all 250ms ease-in;
        background: $color-error-pressed;
        border-color: $color-error-hover;

        .p-button-label {
          transition: all 250ms ease-in;
          color: $color-error-hover;
        }

        .p-button-icon {
          transition: all 250ms ease-in;
          color: $color-error-hover;
        }
      }

      &:disabled {
        transition: all 250ms ease-in;
        border-color: $color-error-disabled;

        .p-button-label {
          transition: all 250ms ease-in;
          color: $color-error-disabled;
        }

        .p-button-icon {
          transition: all 250ms ease-in;
          color: $color-error-disabled;
        }
      }

      .p-button-label {
        font-size: 14px;
        line-height: 16px;
        color: $color-error;
        font-weight: 400;
        transition: all 300ms ease-in-out;
      }

      .p-button-icon {
        color: $color-error;
      }

      &.btn__secondary--bold {
        .p-button-label {
          font-weight: 600;
        }
      }
    }

    &.btn--view-details {
      padding: 8px 32px;
      border-radius: 4px;

      .p-button-label {
        font-size: 12px;
        font-weight: 600;
      }
    }

    &.btn__secondary--bold {
      .p-button-label {
        font-weight: 600;
      }
    }
  }

  &.btn__secondary--b {
    background: none;
    color: $color-gray-1;
    border: 1px solid $color-gray-1;
    transition: all 300ms ease-in;

    &:hover {
      transition: all 250ms ease-in;
      color: $color-gray-35;
      background: $color-gray-55;
      border-color: $color-gray-35;

      .p-button-label {
        transition: all 250ms ease-in;
        color: $color-gray-35;
      }
    }

    &:focus {
      transition: all 250ms ease-in;
      color: $color-gray-35;
      background: $color-gray-5;
      border-color: $color-gray-35;

      .p-button-label {
        transition: all 250ms ease-in;
        color: $color-gray-35;
      }
    }

    &:disabled {
      background: none;
      border-color: $color-gray-5;

      .p-button-label {
        transition: all 250ms ease-in;
        color: $color-gray-5;
      }
    }

    .p-button-label {
      font-size: 14px;
      line-height: 16px;
      color: $color-gray-35;
      font-weight: 400;
      transition: all 300ms ease-in-out;
    }

    .p-button-icon {
      color: $color-gray-35;
    }

    &.btn__secondary--bold {
      .p-button-label {
        font-weight: 600;
      }
    }
  }

  &.btn__terciary {
    background: none;
    border: none;

    &:hover {
      background: none;

      .p-button-label {
        color: $color-primary-hover;
      }
    }

    &:focus {
      background: none;

      .p-button-label {
        color: $color-primary-pressed;
      }
    }

    &:disabled {
      background: none;

      .p-button-label {
        color: $color-primary-disabled;
      }
    }

    .p-button-label {
      font-size: 14px;
      line-height: 16px;
      color: $color-primary;
      font-weight: 500;
    }

    .p-button-icon {
      color: $color-primary;
      margin-right: 7px;
    }

    &.btn__terciary--red {
      background: none;
      color: $color-error;
      border: none;
      transition: all 300ms ease-in;

      &:hover {
        transition: all 250ms ease-in;
        background: $color-error-pressed;

        .p-button-label {
          transition: all 250ms ease-in;
          color: $color-error-hover;
        }

        .p-button-icon {
          transition: all 250ms ease-in;
          color: $color-error-hover;
        }
      }

      &:focus {
        transition: all 250ms ease-in;
        background: $color-error-pressed;

        .p-button-label {
          transition: all 250ms ease-in;
          color: $color-error-hover;
        }

        .p-button-icon {
          transition: all 250ms ease-in;
          color: $color-error-hover;
        }
      }

      &:disabled {
        transition: all 250ms ease-in;

        .p-button-label {
          transition: all 250ms ease-in;
          color: $color-error-disabled;
        }

        .p-button-icon {
          transition: all 250ms ease-in;
          color: $color-error-disabled;
        }
      }

      .p-button-label {
        font-size: 12px;
        line-height: 16px;
        color: $color-error;
        font-weight: 400;
        transition: all 300ms ease-in-out;
      }

      .p-button-icon {
        color: $color-error;
      }

      &.btn__secondary--bold {
        .p-button-label {
          font-weight: 600;
        }
      }
    }
  }

  &.btn__size--xxs {
    padding: 4px 12px;
    border-radius: 4px;

    .p-button-label {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  &.btn__size--xs {
    padding: 8px 16px;
    border-radius: 6px;

    .p-button-label {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  &.btn__size--sm {
    padding: 7px 21px;
    border-radius: 6px;

    .p-button-label {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  &.btn__size--lg {
    padding: 11px 21px;

    .p-button-label {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  &.btn__size--md {
    border-radius: 8px;
    padding: 8px 16px;

    .p-button-label {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }
}

.flat-button {
  background-color: $color-background-tables;
  border-radius: 16px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  border: 0;
  padding: 16px 24px;
  color: $color-gray-1;
  display: flex;
  align-items: baseline;

  i {
    color: $color-primary;
    margin-right: 10px;
  }

  &:hover {
    background: $color-gray-5;
  }

  &.selected {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
}
