@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?v85vp6');
  src:  url('fonts/icomoon.eot?v85vp6#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?v85vp6') format('truetype'),
    url('fonts/icomoon.woff?v85vp6') format('woff'),
    url('fonts/icomoon.svg?v85vp6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-campanita:before {
  content: "\e924";
  color: #ffa115;
}
.icon-campanitavacia:before {
  content: "\e934";
  color: #747474;
}
.icon-timbresvg:before {
  content: "\e935";
  color: #ffa115;
}
.icon-timbrevacio:before {
  content: "\e936";
  color: #747474;
}
.icon-bandejiten:before {
  content: "\e907";
}
.icon-administradores:before {
  content: "\e900";
  color: #B8B8B8;
  font-size: 19px;
}
.icon-blackid-circle:before {
  content: "\e901";
  color: #10c9be;
  font-size: 25px;
}
.icon-calendar-no-bookings .path1:before {
  content: "\e902";
  color: rgb(9, 58, 56);
}
.icon-calendar-no-bookings .path2:before {
  content: "\e903";
  margin-left: -0.9287109375em;
  color: rgb(116, 116, 116);
}
.icon-categorias:before {
  content: "\e904";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-chart:before {
  content: "\e905";
  color: #B8B8B8;
  font-size: 20px;
}
.icon-comercios:before {
  content: "\e906";
  color: #B8B8B8;
  font-size: 30px;
}
.icon-config:before {
  content: "\e908";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-control-de-acceso:before {
  content: "\e909";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-control-real-time:before {
  content: "\e90a";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-cruz-circulo:before {
  content: "\e90b";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-delivery:before {
  content: "\e90c";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-delivery-takeaway:before {
  content: "\e90d";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-divisor-line:before {
  content: "\e90e";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-dots:before {
  content: "\e90f";
  color: #10c9be;
  font-size: 25px;
}
.icon-edit:before {
  content: "\e910";
  color: #10c9be;
  font-size: 19px;
}
.icon-envelop:before {
  content: "\e911";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-eventos:before {
  content: "\e912";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-eventos-control:before {
  content: "\e913";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-geo:before {
  content: "\e914";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-good-circle-black:before {
  content: "\e915";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-house:before {
  content: "\e916";
  color: #B8B8B8;
  font-size: 19px;
}
.icon-icon-tw:before {
  content: "\e917";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-info:before {
  content: "\e918";
  color: #5597cd;
  font-size: 25px;
}
.icon-listado:before {
  content: "\e919";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-lista-espera:before {
  content: "\e91a";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-logout:before {
  content: "\e91b";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-menu:before {
  content: "\e91c";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-no-event-selected .path1:before {
  content: "\e91d";
  color: rgb(9, 58, 55);
}
.icon-no-event-selected .path2:before {
  content: "\e91e";
  margin-left: -0.9228515625em;
  color: rgb(16, 16, 16);
}
.icon-no-event-selected .path3:before {
  content: "\e91f";
  margin-left: -0.9228515625em;
  color: rgb(116, 116, 116);
}
.icon-no-event-selected .path4:before {
  content: "\e920";
  margin-left: -0.9228515625em;
  color: rgb(116, 116, 116);
}
.icon-no-waiting-list:before {
  content: "\e921";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-opciones:before {
  content: "\e922";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-orders:before {
  content: "\e923";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-bandejiten:before {
  content: "\e907";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-persons:before {
  content: "\e925";
  color: #747474;
  font-size: 16px;
}
.icon-plus-circle:before {
  content: "\e926";
  color: #000000;
  font-size: 16px;
  -webkit-text-stroke-width: 1px;
}
.icon-productos:before {
  content: "\e927";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-qr-code:before {
  content: "\e928";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-refresh:before {
  content: "\e929";
  color: #10c9be;
  font-size: 25px;
}
.icon-reservas:before {
  content: "\e92a";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-row-left:before {
  content: "\e92b";
  color: #10c9be;
  font-size: 25px;
}
.icon-rows-up-down:before {
  content: "\e92c";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-row-up-disabled:before {
  content: "\e92d";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-time:before {
  content: "\e92e";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-trash:before {
  content: "\e92f";
  color: #10c9be;
  font-size: 18px;
}
.icon-tw:before {
  content: "\e930";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-user:before {
  content: "\e931";
  color: #B8B8B8;
  font-size: 25px;
}
.icon-usuarios:before {
  content: "\e932";
  color: #B8B8B8;
  font-size: 19px;
}
.icon-warning:before {
  content: "\e933";
  color: #B8B8B8;
  font-size: 25px;
}
