////
/// Colors
/// Init css basic colors
/// @group Colors
////

:root {
  --color-background: #141414;

  --color-gray-9: #616161;
  --color-gray-8: #434343;
  --color-gray-7: #040404;
  --color-gray-6: #101010;
  --color-gray-55: #1C1C1C;
  --color-gray-5: #2B2B2B;
  --color-gray-45: #5E5E5E;
  --color-gray-4: #747474;
  --color-gray-35: #B8B8B8;
  --color-gray-3: #D6D6D6;
  --color-gray-2: #E9E9E9;
  --color-gray-1: #F3F3F3;
}

// Figma pallette color
$color-gray-9: #616161;
$color-gray-8: #434343;
$color-gray-7: #040404;
$color-gray-6: #101010;
$color-gray-55: #1c1c1c;
$color-gray-5: #2b2b2b;
$color-gray-45: #5e5e5e;
$color-gray-4: #747474;
$color-gray-35: #b8b8b8;
$color-gray-3: #d6d6d6;
$color-gray-2: #e9e9e9;
$color-gray-1: #f3f3f3;
$color-success: #4eac1e;
$color-success-dark: #0a2909;
$color-success-background: #061F06;
$color-error: #b64545;
$color-error-hover: #cc4d4d;
$color-error-disabled: #6d2828;
$color-error-dark: #2b1010;
$color-error-pressed: #0f0707;
$color-warning: #ff9900;
$color-warning-background: #1F1403;
$color-info: #5597cd;
$color-info-background: #050c11;
$color-background-tables: #212121;

//Button Palette Colors
$color-primary: #10c9be;
$color-primary-hover: #14e5d9;
$color-primary-pressed: #02b5aa;
//$color-primary-disabled: #00433f;
$color-primary-disabled: #007770;

$color-primary-secondary-hover: #031716;
$color-primary-secondary-pressed: #020d0c;

// End Figma pallete colors
$color-white: #ffffff;
$color-white-contrast: #dfdfdf;
$color-primary-dark: #009393;
$color-black: #000000;
$color-gray: #373737;
$color-gray-contrast: #282828;
$color-gray-dark: #242424;
$color-yellow: #ff9900;
$color-violet: #3945ec;

.text-primary {
  color: $color-primary !important;
}

.text-primary-hover {
  color: $color-primary-hover !important;
}

.text-primary-pressed {
  color: $color-primary-pressed !important;
}

.text-primary-disabled {
  color: $color-primary-disabled !important;
}

.text-primary-dark {
  color: $color-primary-dark !important;
}

.text-white {
  color: $color-white !important;
}

.text-white-contrast {
  color: $color-white-contrast !important;
}

.text-gray-1 {
  color: $color-gray-1 !important;
}

.text-gray-2 {
  color: $color-gray-2 !important;
}

.text-gray-3 {
  color: $color-gray-3 !important;
}

.text-gray-35 {
  color: $color-gray-35 !important;
}

.text-gray-4 {
  color: $color-gray-4 !important;
}

.text-gray-5 {
  color: $color-gray-5 !important;
}

.text-gray-55 {
  color: $color-gray-55 !important;
}

.text-gray-6 {
  color: $color-gray-6 !important;
}

.text-gray-7 {
  color: $color-gray-7 !important;
}

.text-gray-8 {
  color: $color-gray-8 !important;
}

.text-gray-9 {
  color: $color-gray-9 !important;
}

.text-gray {
  color: $color-gray !important;
}

.text-gray-contrast {
  color: $color-gray-contrast !important;
}

.text-gray-dark {
  color: $color-gray-dark !important;
}

.text-black {
  color: $color-black !important;
}

.text-success {
  color: $color-success !important;
}

.text-success-dark {
  color: $color-success-dark !important;
}

.text-error {
  color: $color-error !important;
}

.text-error-hover {
  color: $color-error-hover !important;
}

.text-error-disabled {
  color: $color-error-disabled !important;
}

.text-error-dark {
  color: $color-error-dark !important;
}

.text-warning {
  color: $color-warning !important;
}

.text-yellow {
  color: $color-yellow !important;
}

.text-info {
  color: $color-info !important;
}

.text-violet {
  color: $color-violet !important;
}

.bg-gray-9 {
  background-color: $color-gray-9 !important;
}

.bg-gray-8 {
  background-color: $color-gray-8 !important;
}

.bg-gray-7 {
  background-color: $color-gray-7 !important;
}

.bg-gray-6 {
  background-color: $color-gray-6 !important;
}

.bg-gray-5 {
  background-color: $color-gray-5 !important;
}

.bg-gray-55 {
  background-color: $color-gray-55 !important;
}

.bg-gray-4 {
  background-color: $color-gray-4 !important;
}

.bg-gray-35 {
  background-color: $color-gray-35 !important;
}

.bg-gray-3 {
  background-color: $color-gray-3 !important;
}

.bg-gray-2 {
  background-color: $color-gray-2 !important;
}

.bg-gray-1 {
  background-color: $color-gray-1 !important;
}

.bg-success {
  background-color: $color-success !important;
}

.bg-success-dark {
  background-color: $color-success-dark !important;
}

.bg-error {
  background-color: $color-error !important;
}

.bg-error-hover {
  background-color: $color-error-hover !important;
}

.bg-error-disabled {
  background-color: $color-error-disabled !important;
}

.bg-error-dark {
  background-color: $color-error-dark !important;
}

.bg-warning {
  background-color: $color-warning !important;
}

.bg-info {
  background-color: $color-info !important;
}

.bg-primary {
  background-color: $color-primary !important;
}

.bg-primary-hover {
  color: $color-primary-hover !important;
}

.bg-primary-pressed {
  color: $color-primary-pressed !important;
}

.bg-primary-disabled {
  color: $color-primary-disabled !important;
}

.bg-white {
  color: $color-white !important;
}

.bg-white-contrast {
  color: $color-white-contrast !important;
}

.bg-primary-dark {
  color: $color-primary-dark !important;
}

.bg-black {
  color: $color-black !important;
}

.bg-gray {
  color: $color-gray !important;
}

.bg-gray-contrast {
  color: $color-gray-contrast !important;
}

.bg-gray-dark {
  color: $color-gray-dark !important;
}

.bg-yellow {
  color: $color-yellow !important;
}

.bg-violet {
  color: $color-violet !important;
}
