@import "../core/colors.scss";

.container-segment {
  margin: auto;
  max-width: 310px;
  min-width: 250px;
  text-align: center;
}

@media screen and (min-width: 480px) {
  .container-segment {
    min-width: 310px;

    &.ml-segment {
      margin-left: 28px;
    }
  }
}

.segmented-control {
  position: relative;
  display: inline-block;
  border: 4px solid $color-gray-5;
  background-color: $color-gray-5;
  font-family: "Helvetica", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  overflow: hidden;
  margin-bottom: -6px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-transition-duration: 400ms;
  -o-transition-duration: 400ms;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -moz-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -o-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-tap-highlight-color: transparent;
}

.segmented-control:nth-child(1) {
  width: 100%;
  color: $color-primary;
}

.segmented-control:nth-child(2) {
  width: 100%;
}

.segmented-control > input {
  position: absolute;
  left: -10000px;
  opacity: 0;
}

.segmented-control > label {
  margin-bottom: 0;
  font-size: 14px;
}

.segmented-control > input[type="checkbox"] ~ label {
  -moz-transition-duration: 0ms;
  -o-transition-duration: 0ms;
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
}

.segmented-control > label > span {
  color: $color-gray-3;
}

.segmented-control > input[type="checkbox"] ~ label:before {
  opacity: 0;
}

.segmented-control > input:disabled:nth-child(1) ~ label:nth-of-type(1) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segmented-control > input:nth-child(1):checked ~ label:nth-of-type(1):after,
.segmented-control > input:nth-child(1):checked ~ label:nth-of-type(1):before {
  opacity: 1;
}

.segmented-control > input:nth-child(1):checked ~ label:nth-of-type(1):after {
  color: $color-gray-5;
  font-weight: bold;
}

.segmented-control > input:nth-child(2):checked ~ label:nth-of-type(2):after {
  color: $color-gray-5;
  font-weight: bold;
}
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(1):after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(1):before,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(1)
  ~ label:after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(1)
  ~ label:before {
  left: 0%;
}
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(2):after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(2):before,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(2)
  ~ label:after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(2)
  ~ label:before {
  left: 0%;
}
.segmented-control > input:disabled:nth-child(2) ~ label:nth-of-type(2) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segmented-control > input:nth-child(2):checked ~ label:nth-of-type(2):after,
.segmented-control > input:nth-child(2):checked ~ label:nth-of-type(2):before {
  opacity: 1;
}
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(2):after,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(2):before,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(2)
  ~ label:after,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(2)
  ~ label:before {
  left: 50%;
}
.segmented-control > label {
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  float: left;
  line-height: 32px;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: inherit;
  -moz-transition-property: none;
  -o-transition-property: none;
  -webkit-transition-property: none;
  transition-property: none;
  -moz-transition-duration: inherit;
  -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -moz-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
  transition-timing-function: inherit;
}

.segmented-control > label:before,
.segmented-control > label:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.segmented-control > label:after {
  color: $color-gray-1;
  content: attr(data-value);
  text-align: center;
  padding: inherit;
  z-index: 10;
  font-style: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  opacity: 0;
  -moz-transition-property: opacity, left;
  -o-transition-property: opacity, left;
  -webkit-transition-property: opacity, left;
  transition-property: opacity, left;
  -moz-transition-duration: inherit;
  -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -moz-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
  transition-timing-function: inherit;
}

.segmented-control > label:after,
.segmented-control > label {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.segmented-control > label:before {
  content: "";
  color: inherit;
  background: currentColor;
  -moz-transition-property: left;
  -o-transition-property: left;
  -webkit-transition-property: left;
  transition-property: left;
  -moz-transition-duration: inherit;
  -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -moz-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
  transition-timing-function: inherit;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.segmented-control > label:first-of-type:nth-last-of-type(1),
.segmented-control > label:first-of-type:nth-last-of-type(1):after,
.segmented-control > label:first-of-type:nth-last-of-type(1):before,
.segmented-control > label:first-of-type:nth-last-of-type(1) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(1) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(1) ~ label:before {
  width: 100%;
}

.segmented-control > label:first-of-type:nth-last-of-type(2),
.segmented-control > label:first-of-type:nth-last-of-type(2):after,
.segmented-control > label:first-of-type:nth-last-of-type(2):before,
.segmented-control > label:first-of-type:nth-last-of-type(2) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(2) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(2) ~ label:before {
  width: 50%;
}
