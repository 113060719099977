@import 'src/theme/helpers/mixins';
@import "src/theme/core/colors";

p-toast {
  .p-toast {
    width: 90% !important;

    &.p-toast-top-right {
      top: 75px;
      right: 5%;
      @include for-breakpoint('ipad') {
        top: 68px;
        right: 20px;
      }
    }

    @include for-breakpoint('ipad') {
      width: 343px !important;
    }
  }
}

p-toastitem {
  .p-toast-message {
    &.p-toast-message-success {
      background: $color-success-background !important;
      border-radius: 10px;
      border: 1px solid $color-success !important;

      .p-toast-message-icon {
        &.pi.pi-check {
          display: none;
        }
      }

      .p-toast-message-content {
        padding: 16px 45px 16px 45px !important;
        margin: 0;
        border: none;

        .p-toast-message-icon {
          display: none !important;
        }

        &:after {
          content: url("../../assets/icon/circle-check-solid.svg");
          position: absolute;
          left: 16px;
          top: 16px;
          width: 16px;
          height: 16px;
          z-index: 9;
        }
      }

      .p-toast-summary {
        font-size: 14px;
        font-weight: 600;
        color: $color-success;
        margin: 0 !important;
      }

      .p-toast-detail {
        font-size: 14px;
        font-weight: 500;
        color: $color-gray-35;
        margin-top: 0px !important;
      }

      .p-toast-message-text {
        margin: 0 !important;
      }

      .p-toast-icon-close {
        font-weight: 600;
        font-size: 16px;
        position: absolute;
        top: 9px;
        right: 16px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background: none;
        }

        .p-toast-icon-close-icon {
          color: $color-gray-35;
          font-size: 16px !important;
        }
      }
    }

    &.p-toast-message-error {
      background-color: $color-error-pressed !important;
      border-radius: 10px;
      border: 1px solid $color-error !important;

      .p-toast-message-icon {
        &.pi.pi-times-circle {
          display: none;
        }
      }

      .p-toast-message-content {
        padding: 16px 45px 16px 45px !important;
        margin: 0;
        border: none;

        .p-toast-message-icon {
          display: none !important;
        }

        &:after {
          content: url("../../assets/icon/triangle-exclamation-solid.svg");
          position: absolute;
          left: 16px;
          top: 16px;
          width: 16px;
          height: 16px;
          z-index: 9;
        }
      }

      .p-toast-summary {
        font-size: 14px;
        font-weight: 600;
        color: $color-error !important;
        margin: 0 !important;
      }

      .p-toast-detail {
        font-size: 14px;
        font-weight: 500;
        color: $color-gray-35;
        margin: 0 !important;
      }

      .p-toast-message-text {
        margin: 0 !important;
      }

      .p-toast-icon-close {
        font-weight: 600;
        font-size: 16px;
        position: absolute;
        top: 11px;
        right: 16px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background: none;
        }

        .p-toast-icon-close-icon {
          color: $color-gray-35;
          font-size: 16px !important;
        }
      }
    }

    &.p-toast-message-warn {
      background-color: $color-warning-background !important;
      border-radius: 10px;
      border: 1px solid $color-warning !important;

      .p-toast-message-icon {
        &.pi.pi-exclamation-triangle {
          display: none;
        }
      }

      .p-toast-message-content {
        padding: 16px 45px 16px 45px !important;
        margin: 0;
        border: none;

        .p-toast-message-icon {
          display: none !important;
        }

        &:after {
          content: url("../../assets/icon/triangle-exclamation-solid-warn.svg");
          position: absolute;
          left: 16px;
          top: 16px;
          width: 16px;
          height: 16px;
          z-index: 9;
        }
      }

      .p-toast-summary {
        font-size: 14px;
        font-weight: 600;
        color: $color-warning;
        margin: 0 !important;
      }

      .p-toast-detail {
        font-size: 14px;
        font-weight: 500;
        color: $color-gray-35;
        margin: 0 !important;
      }

      .p-toast-message-text {
        margin: 0 !important;
      }

      .p-toast-icon-close {
        font-weight: 600;
        font-size: 16px;
        position: absolute;
        top: 11px;
        right: 16px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background: none;
        }

        .p-toast-icon-close-icon {
          color: $color-gray-35;
          font-size: 16px !important;
        }
      }
    }

    &.p-toast-message-info {
      background-color: var(--color-info-background) !important;
      border-radius: 10px;
      border: 1px solid var(--color-info) !important;

      .p-toast-message-icon {
        &.pi.pi-information-circle-outline {
          display: none;
        }
      }

      .p-toast-message-content {
        padding: 16px 45px 16px 45px !important;
        margin: 0;
        border: none;

        .p-toast-message-icon {
          display: none !important;
        }

        &:after {
          content: url("../../assets/icon/info-circle.svg");
          position: absolute;
          left: 16px;
          top: 16px;
          width: 16px;
          height: 16px;
          z-index: 9;
        }
      }

      .p-toast-summary {
        font-size: 14px;
        font-weight: 600;
        color: var(--color-info);
        margin: 0 !important;
      }

      .p-toast-detail {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-gray-35);
        margin: 0 !important;
      }

      .p-toast-message-text {
        margin: 0 !important;
      }

      .p-toast-icon-close {
        font-weight: 600;
        font-size: 16px;
        position: absolute;
        top: 11px;
        right: 16px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background: none;
        }

        .p-toast-icon-close-icon {
          color: var(--color-gray-35);
          font-size: 16px !important;
        }
      }
    }
  }
}
