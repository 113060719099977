////
/// Fonts
/// Init Fonts basic settings
/// @group Fonts
////

// Variables
$font-inter: "Inter", sans-serif;
$font-poppins: "Poppins", sans-serif;

$fs-10: 10px;
$fs-12: 12px;
$fs-14: 14px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;
$fs-22: 22px;
$fs-24: 24px;
$fs-26: 26px;
$fs-28: 28px;
$fs-32: 32px;
$fs-40: 40px;
$fs-48: 48px;
$fs-52: 52px;
$fs-56: 56px;
$fs-60: 60px;
$fs-72: 72px;
$fs-96: 96px;

$lh-0: 0px;
$lh-8: 8px;
$lh-10: 10px;
$lh-12: 12px;
$lh-14: 14px;
$lh-16: 16px;
$lh-18: 18px;
$lh-20: 20px;
$lh-22: 22px;
$lh-24: 24px;
$lh-26: 26px;
$lh-28: 28px;
$lh-30: 30px;
$lh-32: 32px;
$lh-40: 40px;

$fw-100: 100;
$fw-200: 200;
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;

// Import fonts
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/fonts/Inter-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

// Variables
$font-inter: "Inter", sans-serif;
$font-poppins: "Poppins", sans-serif;

.font-poppins {
  font-family: $font-poppins !important;
}

$fs-10: 10px;
$fs-12: 12px;
$fs-13: 13px;
$fs-14: 14px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;
$fs-22: 22px;
$fs-23: 23px;
$fs-24: 24px;
$fs-26: 26px;
$fs-28: 28px;
$fs-32: 32px;
$fs-40: 40px;
$fs-48: 48px;
$fs-52: 52px;
$fs-56: 56px;
$fs-60: 60px;
$fs-72: 72px;

$lh-0: 0px;
$lh-8: 8px;
$lh-10: 10px;
$lh-12: 12px;
$lh-14: 14px;
$lh-16: 16px;
$lh-18: 18px;
$lh-20: 20px;
$lh-22: 22px;
$lh-23: 23px;
$lh-24: 24px;
$lh-26: 26px;
$lh-28: 28px;
$lh-30: 30px;
$lh-32: 32px;
$lh-40: 40px;

$fw-100: 100;
$fw-200: 200;
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;

// Import fonts
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: $fw-700;
  src: url("../../assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: $fw-600;
  src: url("../../assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: $fw-500;
  src: url("../../assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: $fw-400;
  src: url("../../assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter Light";
  font-style: normal;
  font-weight: $fw-300;
  src: url("../../assets/fonts/Inter-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: $fw-400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: $fw-500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: $fw-600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: $fw-700;
  font-style: normal;
}

:root {
  --font-family: $font-inter;
}

body {
  font-family: $font-inter;
  font-weight: $fw-400;
  background: $color-gray-6;
}

h1, h2, h3, h4, h5, h6, p, span, a, div, td, tr, button {
  font-family: $font-inter;
}

/// Font size
.text-xxs {
  font-size: $fs-10 !important;
}

.text-xs {
  font-size: $fs-12 !important;
}

.text-xsm {
  font-size: $fs-13 !important;
}

.text-sm {
  font-size: $fs-14 !important;
}

.text-base {
  font-size: $fs-16 !important;
}

.text-lg {
  font-size: $fs-18 !important;
}

.text-xl {
  font-size: $fs-20 !important;
}

.text-2xl {
  font-size: $fs-22 !important;
}

.text-3xl {
  font-size: $fs-24 !important;
}

.text-4xl {
  font-size: $fs-26 !important;
}

.text-5xl {
  font-size: $fs-28 !important;
}

.text-6xl {
  font-size: $fs-32 !important;
}

.text-7xl {
  font-size: $fs-40 !important;
}

.text-8xl {
  font-size: $fs-48 !important;
}

.text-9xl {
  font-size: $fs-52 !important;
}

.text-10xl {
  font-size: $fs-56 !important;
}

.text-11xl {
  font-size: $fs-60 !important;
}

.text-12xl {
  font-size: $fs-72 !important;
}

.text-13xl {
  font-size: $fs-96 !important;
}

/* Line height */
.lh-0 {
  line-height: $lh-0 !important;
}

.lh-8 {
  line-height: $lh-8 !important;
}

.lh-10 {
  line-height: $lh-10 !important;
}

.lh-12 {
  line-height: $lh-12 !important;
}

.lh-14 {
  line-height: $lh-14 !important;
}

.lh-16 {
  line-height: $lh-16 !important;
}

.lh-18 {
  line-height: $lh-18 !important;
}

.lh-20 {
  line-height: $lh-20 !important;
}

.lh-22 {
  line-height: $lh-22 !important;
}

.lh-24 {
  line-height: $lh-24 !important;
}

.lh-26 {
  line-height: $lh-26 !important;
}

.lh-28 {
  line-height: $lh-28 !important;
}

.lh-30 {
  line-height: $lh-30 !important;
}

.lh-32 {
  line-height: $lh-32 !important;
}

.lh-40 {
  line-height: $lh-40 !important;
}

/* Font weight */
.font-thin {
  font-weight: $fw-100 !important;
}

.font-extralight {
  font-weight: $fw-200 !important;
}

.font-light {
  font-weight: $fw-300 !important;
}

.font-normal {
  font-weight: $fw-400 !important;
}

.font-medium {
  font-weight: $fw-500 !important;
}

.font-semibold {
  font-weight: $fw-600 !important;
}

.font-bold {
  font-weight: $fw-700 !important;
}

.font-extrabold {
  font-weight: $fw-800 !important;
}

.font-black {
  font-weight: $fw-900 !important;
}


.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 22px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}
