////
/// Functions
/// @group Mixins
/// @author Ignacio Rodrigues
////


//// Breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints

@mixin for-breakpoint($breakpoints) {
  @if $breakpoints == mobile-xs {
    @media (min-width: 321px) { @content; } // min-width: 320px
  } @else if $breakpoints == mobile {
    @media (min-width: 481px) { @content; } // min-width: 480px
  } @else if $breakpoints == ipad {
    @media (min-width: 769px) { @content; } // min-width: 768px
  } @else if $breakpoints == desktop {
    @media (min-width: 1025px) { @content; } // min-width: 1024px
  } @else if $breakpoints == desktop-hd {
    @media (min-width: 1441px) { @content; } // min-width: 1440px
  } @else if $breakpoints == desktop-xhd {
    @media (min-width: 1279px) { @content; } // min-width: 1658px
  } @else if $breakpoints == desktop-xxhd {
    @media (min-width: 1727px) { @content; } // min-width: 1658px
  } @else if $breakpoints == desktop-xxxhd {
    @media (min-width: 2559px) { @content; } // min-width: 1658px
  }
}
@mixin custom-breakpoint($breakpoints) {
  @media (min-width: $breakpoints) { @content; }
} 

// How to use mixin, add type breakpoint inside mixin for-breakpoint
// @include for-breakpoint('ipad') {}

@mixin skeleton-light {
  background: 
    linear-gradient(0.25turn, transparent, #333333, transparent),
    linear-gradient(#2B2B2B, #2B2B2B),
    radial-gradient(38px circle at 19px 19px, #2B2B2B 50%, transparent 51%),
    linear-gradient(#2B2B2B, #2B2B2B);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  will-change: transform;
  animation: loading 1.6s linear infinite;
}
@mixin skeleton {
  background: 
    linear-gradient(0.25turn, transparent, #242424, transparent),
    linear-gradient(#1C1C1C, #1C1C1C),
    radial-gradient(38px circle at 19px 19px, #1C1C1C 50%, transparent 51%),
    linear-gradient(#1C1C1C, #1C1C1C);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  will-change: transform;
  animation: loading 1.6s linear infinite;
}
