@import "src/theme/core/settings.scss";
@import "src/theme/core/colors.scss";
@import "src/theme/core/fonts.scss";
@import "./dropdown.scss";

.table__internals {
  .p-datatable .p-datatable-header {
    background: $color-gray-55 !important;
  }

  .p-datatable .p-datatable-header {
    background: $color-gray-55 !important;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: $color-gray-55 !important;
  }

  .p-datatable .p-datatable-tbody > tr.table-row {
    background: $color-gray-5;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    background: $color-gray-5 !important;
  }

  .p-datatable .p-datatable-tbody > tr {
    background: $color-gray-5 !important;
  }

  .space {
    background-color: $color-gray-55 !important;
  }

  .p-datatable-wrapper {
    background: $color-gray-55 !important;
  }

  .p-paginator {
    background: $color-gray-55 !important;
  }
}

.p-datatable .p-datatable-header {
  border: $pt-0 !important;
  background: $color-gray-6 !important;
  color: $color-gray-1 !important;
  border-top-left-radius: $br-16 !important;
  border-top-right-radius: $br-16 !important;
  padding: $pt-0 !important;
}

.p-datatable .p-datatable-thead > tr > th {
  border: $pt-0 !important;
  background: $color-gray-6 !important;
  color: $color-gray-2 !important;
  font-size: $fs-12 !important;
  font-weight: $fw-400 !important;
  padding: $pt-0 $pt-0 $pt-8 $pt-16 !important;
}

.p-datatable .p-datatable-footer {
  border: $pt-0 !important;
  background: $color-gray-5 !important;
  color: $color-gray-1 !important;
}

.p-datatable .p-datatable-tbody > tr.table-row {
  background: $color-gray-5;
  color: $color-gray-1;
  transition: box-shadow 0.2s;
  outline: none !important;
  border-radius: $br-8;
  margin-bottom: $pt-8;
  font-size: $fs-14;
  line-height: $lh-16;
  position: relative;

  &:hover {
    cursor: pointer;
    //transform: scale(1.01, 1.01);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.45);
    transition: all 50ms ease-in-out;
    z-index: 5;
  }

  &.extend {
    cursor: pointer;
    //transform: scale(1.01, 1.01);
    box-shadow: none;
    transition: all 50ms ease-in-out;
    z-index: 8;

    &:hover {
      box-shadow: none;
    }
  }

  &.expanded {
    //transform: scale(1.01, 1.01);
    box-shadow: none;

    & > td:first-child {
      border-top-left-radius: $br-8;
      border-bottom-left-radius: $br-0;
    }

    & > td:last-child {
      border-top-right-radius: $br-8;
      border-bottom-right-radius: $br-0;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.p-datatable.p-datatable-hoverable-rows
.p-datatable-tbody
> tr.table-row:not(.p-highlight):hover {
  background: $color-gray-5;
  color: $color-gray-1;
}

.p-datatable .p-datatable-tbody > tr.table-row > td:first-child {
  border-top-left-radius: $br-8;
  border-bottom-left-radius: $br-8;
}

.p-datatable .p-datatable-tbody > tr.table-row > td:last-child {
  border-top-right-radius: $br-8;
  border-bottom-right-radius: $br-8;
}

.p-datatable .p-datatable-tbody > tr > td {
  background: $color-gray-55 !important;
  min-height: 48px;
  padding: 8px 16px !important;
  word-break: break-word;
  vertical-align: middle;
  border: none !important;

  &.under-review {
    background: #ff990050 !important;
  }
}

.p-datatable .p-datatable-tbody > tr {
  background: $color-gray-55 !important;
}

.p-datatable img {
  border-radius: $br-2;
}

.p-datatable-wrapper {
  background: $color-gray-6 !important;
  //overflow-x: auto !important;
  padding: $pt-32 $pt-24 0 !important;
}

p-table::ng-deep .ui-table table {
  table-layout: auto !important;
}

.p-datatable .p-paginator .p-paginator-current {
  margin-right: 90px;
}

.space {
  background-color: $color-gray-6 !important;
}

// Button box with arrows
.table-drop-button {
  color: $color-primary !important;
  background-color: $color-gray-7 !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: $br-4 !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border-radius: $br-4;
  background: $color-gray-6 !important;
  border-color: $color-gray-6 !important;
  color: $color-gray-2 !important;
  font-weight: $fw-600;
  font-size: $fs-16;
  line-height: $lh-16;
  margin: $pt-0 $pt-16;
}

.p-paginator-element {
  color: $color-gray-4 !important;
  opacity: 1 !important;

  .p-paginator-icon {
    font-size: $fs-23 !important;
  }
}

.p-paginator-element.p-paginator-prev,
.p-paginator-element.p-paginator-first,
.p-paginator-element.p-paginator-next,
.p-paginator-element.p-paginator-last {
  min-width: 20px !important;
}

.p-link:focus {
  box-shadow: none !important;
}

.pi-sort-alt:before {
  font-size: $fs-12 !important;
  color: $color-gray-4 !important;
}

p-table::ng-deep .p-datatable .p-datatable-tbody > tr.table-row {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

p-table::ng-deep .p-datatable .p-datatable-tbody > tr.table-row.row-order-open {
  //transform: scale(1.01, 1.01);
  box-shadow: none;

  td {
    background: #1c1c1c !important;

    &.under-review {
      background: $color-yellow !important;
    }
  }

  td:nth-last-child(1) {
    border-bottom-right-radius: 0;
  }

  td:nth-child(1) {
    border-bottom-left-radius: 0;
  }
}

p-table::ng-deep .p-datatable .p-paginator-bottom {
  position: relative;
  border: 0 !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: $pt-24 $pt-24;
  border-bottom-left-radius: $br-16;
  border-bottom-right-radius: $br-16;

  .p-paginator-last.p-paginator-element {
    margin-right: auto;
  }
}

p-table::ng-deep .p-datatable {
  .p-paginator .p-dropdown .p-dropdown-label {
    display: flex !important;
    align-items: center !important;
  }
}

// Sub table
.sub-table-container {
  //transform: scale(1.01, 1.01);

  & > td {
    padding: 0 !important;
    background: $color-gray-6;

    .sub-table-col {
      margin-top: -8px;
      margin-bottom: 30px;
      padding: 0 !important;
    }
  }

  .p-datatable .p-datatable-thead > tr > th {
    padding: $pt-16 $pt-0 $pt-16 $pt-0 !important;
  }
}

.subtable-row > td {
  &:first-child {
    border-top-left-radius: $br-8;
    border-bottom-left-radius: $br-8;
  }

  &:last-child {
    border-top-right-radius: $br-8;
    border-bottom-right-radius: $br-8;
  }
}

.sub-table-container {
  //transform: scale(1.01, 1.01);
}

.sub-table-container > td {
  padding: 0 !important;
  background: $color-gray-6;

  .sub-table-col {
    margin-top: -8px;
    margin-bottom: 30px;
    padding: 0 !important;
  }

  p-table::ng-deep .p-datatable-thead > tr > th {
    padding: $pt-8 $pt-16 !important;
    background-color: $color-gray-5 !important;
  }

  p-table::ng-deep .p-datatable-wrapper {
    background-color: $color-gray-5 !important;
    border-bottom-right-radius: $pt-8 !important;
    border-bottom-left-radius: $pt-8 !important;
  }
}

.p-button.p-button-text.p-button-plain {
  color: $color-primary !important;
  background-color: $color-gray-7;
  width: 25px !important;
  height: 25px !important;
  border-radius: $br-4 !important;

  &:hover {
    background-color: $color-gray-7 !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.user-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  overflow-clip-margin: unset;
  overflow: hidden;
}

.icon-placeholder {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: $color-gray-6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-datatable .p-sortable-column .p-sortable-column-bid-badge {
  display: none !important;
}

.table-events {
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 16px !important;
  }
}
