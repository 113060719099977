@import "../core/colors.scss";

.bid-badge {
  width: fit-content;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600 !important;
  padding: 4px 8px;
  text-transform: uppercase;
  white-space: nowrap;
  color: $color-gray-3;
  background-color: $color-gray-6;

  &.bid-badge--big {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    padding: 0 8px;
  }

  &.bid-badge--green {
    color: $color-success;
  }

  &.bid-badge--orange {
    color: $color-warning;
  }

  &.bid-badge--red {
    color: $color-error;
  }

  &.bid-badge--white {
    color: $color-gray-3;
  }

  &.bid-badge--cyan {
    color: $color-primary;
  }

  &.bid-badge--grey {
    color: $color-gray-4;
  }

  &.bid-badge--bg-green {
    background-color: $color-success;
    color: #101010 !important;
  }

  &.bid-badge--bg-orange {
    background-color: $color-warning;
    color: #101010 !important;
  }

  &.bid-badge--bg-red {
    background-color: $color-error;
    color: #101010 !important;
  }

  &.bid-badge--bg-white {
    background-color: $color-gray-3;
    color: #101010 !important;
  }

  &.bid-badge--bg-primary {
    background-color: $color-primary;
    color: #101010 !important;
  }

  &.bid-badge--bg-info {
    background-color: $color-info;
    color: #101010 !important;
  }

  &.bid-badge--bg-grey {
    background-color: $color-gray-4;
    color: #101010 !important;
  }

  &.bid-badge--full-width {
    width: 100%;
    text-align: center;
  }
}
