@import "./core/colors";
@import "./core/icons";
@import "./core/fonts";
@import "./modules/calendar";
@import "./modules/select";
@import "./modules/toast";

.pi-sort-amount-up-alt:before,
.pi-sort-amount-down:before {
  color: $color-primary;
  font-size: 13px;
  position: relative;
  bottom: 3px;
}

.pi-sort-alt:before {
  font-size: 10px;
  position: relative;
  bottom: 4px;
  color: #e9e9e9;
}

.custom-bid-badge.count-bid-badge {
  z-index: 10;
  position: absolute;
  left: -5px;
  top: -15px;

  span {
    background-color: $color-primary;
    color: $color-black;
    border-radius: 5px;
    font-size: 12px;
    border: none;
  }
}

.p-grid {
  width: 100% !important;
}

.p-fluid .p-button-icon-only {
  width: 2.357rem;
}

/* Styles file */

.p-fileupload .p-fileupload-buttonbar {
  background: transparent !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.p-fileupload .p-fileupload-content {
  background: transparent !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.p-panelmenu .p-panelmenu-header > a,
.p-panelmenu-header,
.p-panelmenu-content {
  border: none !important;
  background: transparent !important;
}

.p-panelmenu {
  padding: 0 10px !important;
}

.p-menu .p-menuitem {
  &.hide {
    display: none !important;
  }
}

.p-panelmenu
.p-panelmenu-content
.p-menuitem
.p-menuitem-link:not(.p-disabled):hover {
  background: $color-gray-5 !important;
  border-radius: 10px !important;
  color: $color-gray-3 !important;
}

.p-menuitem-icon {
  color: $color-gray-35 !important;
}

.p-panelmenu .p-panelmenu-header {
  background: $color-gray-5 !important;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  box-shadow: none !important;
  background: $color-gray-5 !important;
  border-radius: 10px !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  box-shadow: none !important;
  background: $color-gray-5 !important;
  border-radius: 10px !important;
}

.p-toolbar {
  background: transparent !important;
  border: 0 !important;
}

.p-paginator {
  border: 1px solid $color-gray !important;
  background: $color-gray-6 !important;
  color: $color-gray-1 !important;
}

.table__container {
  background: $color-gray-55;
  border-radius: 16px;
}

.p-tabview .p-tabview-panels {
  padding: 1rem 1rem 2rem !important;
}

.buttons-bottom {
  position: absolute;
  bottom: -80px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

:root {
  --fc-border-color: $color-gray-3 !important;
}

.fc .fc-button-primary:disabled {
  color: $color-gray-dark !important;
  background-color: $color-gray-1 !important;
}

.p-tabview .p-tabview-panels {
  background: $color-gray-5 !important;
  color: $color-gray-1 !important;
}

.p-tabview .p-tabview-nav {
  background: transparent !important;
  color: $color-gray-1 !important;
  border: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  margin: 0 1px !important;
  background: $color-gray-dark !important;
  color: $color-gray-1 !important;
  border: none !important;
  text-decoration: none !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;

  &:hover {
    background: $color-gray-5 !important;
  }
}

.p-highlight.ng-star-inserted {
  .p-tabview-nav-link.p-ripple {
    background: $color-gray-5 !important;
  }
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  border-radius: 5px !important;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  border-radius: 5px !important;
}

.p-button {
  color: $color-gray-dark;
  background: $color-gray-1;
  border-color: $color-gray-1;
}

.p-button-down:hover {
  background: $color-gray-dark !important;
}

.p-fileupload-choose {
  font-weight: bold !important;
  color: $color-gray-dark !important;
  background: $color-gray-1 !important;
  border-color: $color-gray-1 !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link:hover {
  text-decoration: none !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  color: $color-gray-1;
}

.fc-daygrid-more-link {
  color: $color-gray-1 !important;
}

.custom-dialog.small.qr-dialog {
  width: 450px !important;

  .p-dialog-header {
    position: relative;
    background: $color-gray-55 !important;
  }

  .p-dialog-content {
    background: $color-gray-55 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .p-dialog-footer {
    background: $color-gray-55;
    border-color: $color-gray-55;
    color: $color-gray-1;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }
}

.fc .fc-toolbar .fc-button {
  font-weight: bold !important;
  color: $color-gray-dark !important;
  background: $color-gray-1 !important;
  border-color: $color-gray-1 !important;
}

.p-button {
  border-radius: 30px;
}

.pt-60 {
  padding-top: 77px;
}

::-webkit-scrollbar {
  width: 10px;
}

// Track
::-webkit-scrollbar-track {
  background-color: transparent;
}

// Handle
::-webkit-scrollbar-thumb {
  background: $color-gray-6;
  border-radius: 10px;
  margin: 1px 0;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  background: $color-gray-8 !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: $color-primary;
}

.refresh {
  .p-button {
    background-color: $color-gray !important;
    border-color: $color-primary !important;
    color: $color-gray-1 !important;
  }
}

.a-terms {
  cursor: pointer;
  text-decoration: none !important;
  color: $color-primary !important;
}

.p-highlight .p-tabview-nav-link {
  background: $color-gray-6 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.box-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.fc-theme-standard .fc-popover {
  background: $color-gray-dark !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: $color-gray !important;
  border-color: transparent !important;
  background: $color-gray-contrast !important;
  box-shadow: none !important;
}

.p-dialog .p-confirm-dialog-message {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.p-dialog .p-confirm-dialog-message .topheader {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 24px;
  color: $color-gray-1;

  &.no-subheader {
    margin-bottom: 0;
  }

  &.subheader {
    font-family: 'Inter', sans-serif;
  }
}

.p-paginator .p-paginator-current {
  color: $color-gray-3 !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border-color: transparent;
}

.booking-list .p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.booking-list .p-accordion .p-accordion-content {
  background: $color-gray-6;
  color: $color-gray-35;
  border: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: 32px;

  p:first-child {
    display: flex;
    justify-content: space-between;
    color: $color-gray-1;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 8px;
  }

  .btn {
    width: 100%;
    margin-top: 16px;
  }

  .booking-status {
    color: $color-gray-6;
    padding: 0 6px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    line-height: 24px;
    border-radius: 2px;
  }
}

.vip-discount {
  background-color: $color-warning !important;
}

.content-booking {
  width: 100%;
  position: relative;

  .btn.btn-edit {
    width: auto;
    position: absolute;
    top: 60px;
    right: 0;
  }
}

.p-dialog.p-dialog-reservations {
  form {
    background-color: $color-gray-5;
  }

  .p-dialog-header {
    padding: 32px 16px 6px;
  }

  .confirmed + .reservation-details {
    .date {
      background-image: url("../assets/svg-icons/date-confirmed.svg");
    }

    .time {
      background-image: url("../assets/svg-icons/time-confirmed.svg");
    }

    .persons {
      background-image: url("../assets/svg-icons/persons-confirmed.svg");
    }

    .user {
      background-image: url("../assets/svg-icons/user-confirmed.svg");
    }
  }

  .canceled + .reservation-details {
    .date {
      background-image: url("../assets/svg-icons/date-canceled.svg");
    }

    .time {
      background-image: url("../assets/svg-icons/time-canceled.svg");
    }

    .persons {
      background-image: url("../assets/svg-icons/persons-canceled.svg");
    }

    .user {
      background-image: url("../assets/svg-icons/user-canceled.svg");
    }

    .canceled {
      background-image: url("../assets/svg-icons/calendar-canceled.svg");
    }
  }

  .expired + .reservation-details {
    .date {
      background-image: url("../assets/svg-icons/date-expired.svg");
    }

    .time {
      background-image: url("../assets/svg-icons/time-expired.svg");
    }

    .persons {
      background-image: url("../assets/svg-icons/persons-expired.svg");
    }

    .user {
      background-image: url("../assets/svg-icons/user-expired.svg");
    }
  }

  .p-inline-message {
    border-radius: 8px 8px 0 0;
    font-weight: bold;
    color: $color-gray-6;

    .p-inline-message-text {
      font-weight: bold;
    }

    &.p-inline-message-success {
      background: $color-success;
      border-color: $color-success;
    }

    &.p-inline-message-error {
      background: $color-error;
      border-color: $color-error;
    }

    &.p-inline-message-info {
      background: $color-warning;
      border-color: $color-warning;
    }

    .p-inline-message-icon {
      display: none;
    }
  }

  .rounded {
    .p-inline-message {
      border-radius: 8px;
      margin-bottom: 30px;
    }
  }

  .expired {
    .p-inline-message.p-inline-message-error {
      background: $color-gray-4;
      border-color: $color-gray-4;
    }
  }

  .reservation-details {
    background-color: $color-gray-6;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    list-style: none;
    padding: 32px 24px;

    h5 {
      margin-bottom: 14px;
      line-height: 21px;
    }

    .more-info {
      margin-top: 32px;
    }

    li {
      position: relative;
    }
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event {
  background-color: $color-gray-55;
  border: $color-gray-55;
  margin-bottom: 0 !important;
}

.fc-daygrid-day-events {
  padding: 0 8px;
}

.p-datatable .p-datatable-wrapper table {
  table-layout: initial !important;
}

.p-datatable > table {
  table-layout: initial !important;
}

table {
  table-layout: initial;
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: none !important;
}

.table .p-datatable .p-datatable-wrapper {
  overflow-x: scroll !important;
}

.p-progressbar-value.p-progressbar-value-animate {
  background: $color-gray !important;
}

.text-length {
  font-size: 12px;
  color: $color-gray-1;
  margin-top: 8px;
  line-height: 16px;
  font-weight: 400;
}

.p-button-minus {
  padding: 10px 10px !important;
  font-weight: bold !important;
  background-color: $color-gray-1 !important;
  color: $color-gray !important;
  border-color: $color-gray-1 !important;
  outline: none !important;
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;

  &:hover,
  &:active {
    color: $color-gray !important;
    background-color: $color-white-contrast !important;
    border-color: $color-white-contrast !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.p-button-plus {
  padding: 10px 10px !important;
  font-weight: bold !important;
  background-color: $color-gray-5 !important;
  color: $color-gray-1 !important;
  border-color: $color-gray !important;
  outline: none !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;

  &:hover,
  &:active {
    color: $color-gray-1 !important;
    background-color: $color-black !important;
    border-color: $color-black !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.content-style-dialog {
  .p-dialog-content {
    height: 100% !important;
  }
}

.p-dialog-content .icon-warning {
  margin-bottom: 15px;
}

.dialog-order {
  width: 95vw !important;
}

.p-tag-custom {
  background: $color-primary !important;
  color: $color-black !important;
  font-size: 13px !important;
  border-radius: 50% !important;
}

.p-paginator .p-paginator-current {
  position: absolute !important;
  right: 0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.menu-header {
  align-items: center;

  .title-section {
    color: $color-gray-1;
    font-size: 26px;
    line-height: 24px;
    font-weight: 700;
  }

  .section-detail-event {
    margin-top: 32px !important;
    margin-bottom: 8px !important;
  }
}

.p-datatable .p-datatable-wrapper table {
  table-layout: initial !important;
}

.p-datatable > table {
  table-layout: initial !important;
}

// Charts
.c3 > svg {
  width: 100% !important;
}

.c3-line {
  stroke-width: 2px;
}

.c3-chart-arc path {
  stroke: transparent !important;
}

.hide-tooltip {
  .c3-tooltip-container {
    display: none !important;
  }
}

.domain {
  display: none;
}

.tick {
  line {
    display: none;
  }
}

.c3-axis-y text {
  fill: $color-gray-35;
  font-size: 12px;
}

.c3-axis-x text {
  font-size: 12px;
  fill: $color-gray-35;
}

.c3-tooltip td,
th {
  background-color: $color-gray-55;
  color: $color-gray-2;
  border: 1px solid $color-black;
  outline: none;
}

.c3-legend-item {
  display: none;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}

.c3-tooltip table {
  background-color: $color-gray-55;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-password-panel {
  background: $color-gray-6 !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;

  .p-password-info {
    color: $color-gray-1 !important;
  }
}

.chip-totals {
  background-color: $color-gray-5;
  border-radius: 8px;
  padding: 0 12px;
  margin-left: 32px;
}

.p-component:disabled {
  opacity: 1 !important;
}

p-calendar > .p-calendar > .p-inputtext {
  background: $color-gray-55 !important;
  border-radius: 8px !important;
  border: 1px solid $color-gray-4 !important;
  color: $color-gray-1;
}

.dots-menu.p-menu-overlay {
  font-family: 'Poppins';
  background-color: $color-gray-5 !important;
  padding: 8px;
  border-radius: 8px;
  width: 224px !important;

  &.menu-user {
    width: 303px !important;
  }

  .p-menuitem-link {
    border-radius: 4px;
    padding: 10px 8px;
  }

  .p-menuitem-link:not(.p-disabled):hover {
    background-color: $color-gray-6;
    color: $color-gray-1;

    .delete-item {
      .p-menuitem-text {
        color: $color-error;
      }
    }
  }

  .p-menuitem-link .p-menuitem-icon {
    color: $color-gray-1;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    width: 16px;
  }

  .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $color-gray-1;
  }

  .p-menuitem.delete-item {
    .p-menuitem-text {
      color: $color-error;
    }

    .p-menuitem-icon {
      color: $color-error !important;
    }

    .p-menuitem-link:not(.p-disabled):hover {
      .p-menuitem-text {
        color: $color-error;
      }
    }
  }

  .p-menuitem-link .p-menuitem-text {
    color: $color-gray-1;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
  }

  .p-menu-separator {
    border-top: 1px solid $color-gray-55;
  }

}
