////
/// Settings File
/// Init wrappers, breakpoints and more settings
/// @group Settings
////

/// Wrapper ///
///
$wrapper-min-width: 1200px !default;
$wrapper-max-width: 1210px !default;

/// Transitions/Animation ///
///
$transition-duration: 0.25s !default;
$transition-timing: ease !default;

/// Border radius
$br-0: 0px;
$br-2: 2px;
$br-3: 3px;
$br-4: 4px;
$br-6: 6px;
$br-8: 8px;
$br-12: 12px;
$br-16: 16px;

// Puntos
$pt-0: 0px;
$pt-2: 2px;
$pt-4: 4px;
$pt-8: 8px;
$pt-12: 12px;
$pt-16: 16px;
$pt-20: 20px;
$pt-24: 24px;
$pt-28: 28px;
$pt-30: 30px;
$pt-32: 32px;
$pt-36: 36px;
$pt-40: 40px;
$pt-44: 44px;
$pt-48: 48px;
$pt-52: 52px;
$pt-56: 56px;
$pt-60: 60px;
$pt-64: 64px;
$pt-68: 68px;
$pt-72: 72px;
$pt-76: 76px;
$pt-80: 80px;
