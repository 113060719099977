// Required (Must-have for any SCSS file(s) to work)
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Bootstrap Navbar Styles
@import "../node_modules/bootstrap/scss/reboot"; // Important for a stable layout
@import "../node_modules/bootstrap/scss/grid"; // Important for mobile navbar
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/transitions"; // When click on Mobile Button Menu Toggle, the menu gets collapse/not collapse

// Leaflet Geo search styling
@import "../node_modules/leaflet-geosearch/dist/geosearch.css";
@import "../node_modules/leaflet/dist/leaflet.css";

@import "./theme/styles.scss";
@import "../node_modules/bootstrap/scss/bootstrap";

@import url("./assets/fonts/icomoon/style.css");
@import "../node_modules/c3/c3.min.css";
