
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// BiD Modal - General styles
.bid-modal {
  .p-dialog,
  .p-dialog-mask.p-component-overlay {
    backdrop-filter: blur(10px);
    z-index: 1001 !important;
  }
}


p-dialog::ng-deep .center .p-dialog-header {
  justify-content: center;
}

// BiD Modal Large
.bid-modal {
  .p-dialog {
    width: 100%;
    max-width: 552px;
    border-radius: 18px;
  }
}

.bid-modal .p-dialog .p-dialog-header {
  position: relative;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 24px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  background: $color-gray-5;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(180deg, #030303, #525252);
  }
}

.bid-modal .p-dialog .p-dialog-content {
  padding: 24px 0 32px;
  background: $color-gray-5;

  .field__item {
    margin-bottom: 0;
  }

  .field__group {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.filling-space {
  width: 24px;
  height: 24px;
}

.bid-modal .p-dialog .p-dialog-header .p-dialog-header-icons {
  border-radius: 0;

  .p-dialog-header-icon {
    width: 24px !important;
    height: 24px !important;
    color: $color-gray-2;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .pi.pi-times {
      &:before {
        display: none;
      }

      &:after {
        content: url('../../assets/icon/circle-xmark-light.svg');
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.bid-modal.center {
  .p-dialog-header {
    justify-content: center;
  }
}

.bid-modal__header--headtitle {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: $color-gray-1 !important;
  margin: 0 0 8px !important;
}

.bid-modal__header--title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: $color-gray-1 !important;
  margin: 0 !important;
}

.bid-modal .p-dialog .p-dialog-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 32px 24px;
  background: $color-gray-5;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(180deg, #030303, #525252);
  }

  .btn.btn__secondary {
    height: 48px;
    width: 100%;
    font-weight: 500;
    margin: 0;

    .p-button-label {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }

  .btn.btn__primary {
    height: 48px;
    width: 100%;
    font-weight: 500;
    margin: 0;

    &.btn--one {
      width: initial;
      min-width: 184px;
    }

    .p-button-label {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }
}

// BiD Modal Informative
.bid-modal.bid-modal__informative {
  .p-dialog {
    width: 100%;
    max-width: 432px;
    border-radius: 18px;
  }
}

.bid-modal.bid-modal__informative {
  .p-dialog .p-dialog-header {
    padding: 24px 24px 0;

    &:after {
      display: none;
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 24px;
      right: 24px;
      bottom: auto;
      left: auto;
      border-radius: 0;
    }
  }
}

.bid-modal.bid-modal__informative .p-dialog .p-dialog-content {
  padding: 0 24px;
  background: $color-gray-5;
}

.bid-modal.bid-modal__informative .p-dialog .p-dialog-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 40px 24px 24px;
  background: $color-gray-5;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;

  &:before {
    display: none;
  }
}

.bid-modal.bid-modal__warning {
  .p-dialog {
    width: 100%;
    max-width: 432px;
    border-radius: 18px;
  }

  .p-dialog .p-dialog-header {
    padding: 24px 24px 0;

    &:after {
      display: none;
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 24px;
      right: 24px;
      bottom: auto;
      left: auto;
      border-radius: 0;
    }
  }
}

.bid-modal.bid-modal__warning--small {
  .p-dialog {
    width: 100%;
    max-width: 432px;
    border-radius: 18px;
  }

  .p-dialog .p-dialog-header {
    padding: 24px 24px 0;

    &:after {
      display: none;
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 24px;
      right: 24px;
      bottom: auto;
      left: auto;
      border-radius: 0;
    }
  }

  .p-dialog-content {
    flex-direction: column;
    text-align: center;
    padding: 0 24px 32px;
  }

  .p-confirm-dialog-message {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    color: $color-gray-1;
    margin: 32px 0 0 !important;

    .subheader {
      display: block;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: $color-gray-35;
      margin-top: 8px !important;
    }

    .title {
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      color: $color-gray-1;
      margin: 0 !important;
      display: inline-block;

      .text-primary {
        font-weight: 600;
        color: $color-primary !important;
      }
    }
  }

  .p-dialog .p-dialog-footer {
    padding-top: 0;

    &:before {
      display: none;
    }
  }
}

.bid-modal.bid-modal__small {
  .p-dialog {
    width: 100%;
    max-width: 432px;
    border-radius: 18px;
  }

  .p-dialog .p-dialog-header {
    padding: 24px 24px 0;

    &:after {
      display: none;
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 24px;
      right: 24px;
      bottom: auto;
      left: auto;
      border-radius: 0;
    }
  }

  .p-dialog .p-dialog-footer {
    padding-top: 0;

    &:before {
      display: none;
    }
  }
}

.bid-modal.bid-moda__cropimage {
  .p-dialog {
    width: 100%;
    max-width: 800px;
    border-radius: 18px;
  }
}

.event-description-modal .p-dialog-content {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  padding-bottom: 32px !important;
}
