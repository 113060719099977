/* Autofill input */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
}

/* Float label */
.p-float-label > .title-float {
  color: $color-gray-3;
  font-size: 16px;
  font-weight: 400;
  left: 16px;
  background: $color-gray-55;
  z-index: 100;
  margin-top: -0.6rem;

  &:focus {
    margin-top: -1rem;
    color: $color-primary;
  }

  &:hover {
    margin-top: -1rem;
    color: $color-gray-3;
  }
}

/* Float label position */
.p-float-label input:focus ~ .title-float,
.p-float-label input.p-filled ~ .title-float,
.p-float-label textarea:focus ~ .title-float,
.p-float-label textarea.p-filled ~ .title-float,
.p-float-label .p-inputwrapper-focus ~ .title-float,
.p-float-label .p-inputwrapper-filled ~ .title-float {
  top: -0.2rem;
  font-size: 12px;
  padding: 5px 7px;
  left: 12px;
}

/* Float label in focus */
.p-float-label input:focus ~ label {
  color: $color-primary;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: $color-primary;
}

/* Input */
.p-inputtext.ng-touched.p-filled {
  border: 1px solid $color-gray-4;
  box-shadow: none;
}

/* Input field */
.field__item {
  width: 100%;
  position: relative;
  margin-bottom: 24px;

  &:hover {
    .ng-invalid.ng-touched {
      .p-float-label .title-float {
        color: $color-gray-3;
      }
    }

    .p-float-label .title-float {
      color: $color-gray-3;
    }
  }

  .p-float-label .p-inputtext:focus ~ label.title-float {
    color: $color-primary;
  }

  .p-inputtext {
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    background: transparent;
    color: $color-gray-3;
    border-radius: 8px;
    border-color: $color-gray-4;

    &:disabled {
      color: $color-gray-4;
    }

    &:enabled:focus {
      border-color: $color-primary !important;
      box-shadow: none !important;
    }

    &:enabled:hover {
      border-color: $color-gray-3;
      box-shadow: none !important;
    }
  }
}

/* Float label in modal */
.field--modal {
  .p-float-label > .title-float {
    color: $color-gray-3;
    font-size: 16px;
    font-weight: 400;
    left: 16px;
    background: $color-gray-5;
    z-index: 100;
    margin-top: -0.6rem;

    &:focus {
      margin-top: -1rem;
    }
  }

  &:enabled:focus {
    border-color: $color-primary;

    &.ng-invalid.ng-dirty + .title-float {
      color: $color-primary;
    }
  }

  &:enabled:hover {
    border-color: $color-gray-3;
    box-shadow: none;

    /*&.ng-invalid.ng-dirty + .title-float {
      color: $color-gray-3;
    }*/
  }
}


/*p-inputmask.ng-dirty.ng-invalid>.p-inputtext {
  border-color: $color-gray-3 !important;
}*/

.field__item.input-mask {
  .field__item--error {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    margin-top: 5px;
    font-size: 11px;
    color: $color-error;
    display: block;

    &:after {
      content: url("../../assets/icon/icon-error.svg");
      position: absolute;
      right: 16px;
      top: -34px;
      height: 16px;
      width: 19px;
      z-index: 9;
    }
  }
}

/* Field item - Extras */
.field__item {
  /* Message error */
  .field__item--error {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    margin-top: 5px;
    font-size: 11px;
    color: $color-error;

    &:after {
      content: url("../../assets/icon/icon-error.svg");
      position: absolute;
      right: 16px;
      top: -50px;
      height: 16px;
      width: 19px;
      z-index: 9;
    }
  }

  /* View password btn */
  .view--password {
    position: absolute;
    right: 16px;
    top: 13px;
    cursor: pointer;
  }

  /* Field info */
  .field__item--info {
    margin: 8px 0 0;
    font-size: 12px;
    line-height: 16px;

    span {
      font-size: 12px;
      line-height: 16px;
      color: $color-gray-1;
    }
  }

  /* Field Spinner */
  .field__item--spinner {
    position: absolute;
    right: 16px;
    top: 13px;
  }

  /* Dropdown */
  .dropdown__list {
    position: absolute;
    background: $color-gray-6;
    max-height: 200px;
    width: 100%;
    display: none;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 999;

    .dropdown__items {
      border: 1px solid $color-gray-55;
      color: $color-gray-1;
      padding: 12px 16px;
      text-decoration: none;
      cursor: pointer;
      display: block;
    }
  }

  /* Calendar */
  p-calendar {
    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
      color: $color-gray-1;
      background: $color-gray-6;
      border-bottom: none;

      .p-datepicker-next:enabled:hover {
        background-color: $color-gray-55;
        box-shadow: none;
      }

      .p-datepicker-prev:enabled:hover {
        background-color: $color-gray-55;
        box-shadow: none;
      }
    }

    .p-datepicker.p-component {
      color: $color-gray-1;
      background: $color-gray-6;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .p-datepicker table td.p-datepicker-today > span.p-highlight {
      background-color: $color-primary;
      color: $color-gray-5;
    }

    .p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):hover {
      background-color: $color-primary;
      color: $color-gray-5;
    }

    .p-datepicker table td > span.p-highlight {
      background-color: $color-primary;
      color: $color-gray-5;
    }
  }
}

/* Input field - Mask */
.field__item {
  &.field__item--mask {
    .p-inputmask {
      &:hover {
        & + .title-float {
          color: $color-gray-3;
        }
      }

      &.p-inputwrapper-focus {
        .p-inputmask {
          border-color: $color-primary;
          box-shadow: 0 0 0 4px rgba(0, 219, 206, 0.35);
        }

        & + .title-float {
          color: $color-primary;
        }

        & + .field__item--error {
          display: none;
        }
      }

      &.ng-invalid.ng-dirty > .p-inputmask {
        &:focus {
          border-color: $color-primary;
          box-shadow: 0 0 0 4px rgba(0, 219, 206, 0.35);
        }
      }
    }

    &.has-typing {
      .field__item--error {
        display: none;
      }

      .p-float-label > .ng-invalid.ng-dirty + label {
        color: $color-gray-3;
      }

      p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
        border: 1px solid $color-gray-4;
      }

      .p-inputmask.p-inputtext.ng-dirty.ng-invalid:enabled:focus {
        border: 1px solid $color-primary;
        box-shadow: 0 0 0 4px rgba(0, 219, 206, 0.35);
      }

      .p-float-label > .p-inputmask {
        &.p-inputwrapper-focus + .title-float {
          color: $color-primary;
        }
      }

      &.p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
        border: 1px solid $color-gray-4;
        box-shadow: none;
      }

      .p-inputmask.ng-dirty.ng-invalid:enabled:focus {
        & + label {
          color: $color-primary;
        }
      }
    }

    &.has-error {
      .p-inputmask {
        .p-inputmask {
          border-color: $color-error;
          box-shadow: none;
        }

        & + .title-float {
          color: $color-error;
        }
      }
    }

    &.has-filled {
      &.p-inputwrapper-focus {
        & + .title-float {
          color: $color-primary;
        }
      }
    }
  }
}

/* Validations input field */
.field__item {
  /* Has Typing */
  &.has-typing {
    .field__item--error {
      display: none;
    }

    .p-inputtext.ng-dirty.ng-invalid {
      border: 1px solid $color-gray-4;
      box-shadow: none;
    }

    .p-float-label > .ng-invalid.ng-dirty + label {
      color: $color-gray-3;
    }

    .p-inputtext.ng-dirty.ng-invalid:enabled:focus {
      border: 1px solid $color-primary;
      box-shadow: 0 0 0 4px rgba(0, 219, 206, 0.35);
    }

    .p-inputtext.ng-dirty.ng-invalid:enabled:focus {
      & + label {
        color: $color-primary;
      }
    }

    &:enabled:hover {
      .p-inputtext.ng-dirty.ng-invalid {
        border: 1px solid $color-primary;
        box-shadow: 0 0 0 4px rgba(0, 219, 206, 0.35);
      }

      .p-float-label > .ng-invalid.ng-dirty + label {
        color: $color-primary;
      }
    }
  }

  /* Error input */
  &.has-error {
    margin-bottom: 43px;

    .p-inputtext {
      border: 1px solid $color-error;

      &.ng-invalid {
        border-color: $color-error;
      }
    }

    .title-float {
      color: $color-error;
    }


    .ng-invalid.ng-dirty + label {
      color: $color-error;
    }

    .field__item--error {
      display: block;
    }

    .field__item--info {
      margin: 24px 0px -20px;
    }

    .p-inputtext:enabled:hover {
      border-color: $color-error;
      box-shadow: none;
    }

    .p-inputtext:enabled:focus {
      border-color: $color-error !important;
      box-shadow: none !important;

      & ~ label.title-float {
        color: $color-error;
      }
    }

    &:hover {
      .field__item--error {
        display: block;
      }

      .p-float-label .title-float {
        color: $color-error;
      }
    }
  }

  /* Valid input */
  &.has-valid {
    .p-inputtext {
      border: 1px solid $color-gray-4;
      box-shadow: none;
    }

    .p-dropdown-label.p-inputtext {
      border: none;
    }

    .title-float {
      color: $color-gray-3;
    }

    .p-float-label > .ng-invalid.ng-dirty + label {
      color: $color-gray-3;
    }

    .field__item--error {
      display: none;
    }
  }

  /* Filled input */
  &.has-filled {
    .p-inputtext {
      border: 1px solid $color-primary;
      box-shadow: 0 0 0 4px rgba(0, 219, 206, 0.35);

      &:enabled:focus {
        box-shadow: 0 0 0 4px rgba(0, 219, 206, 0.35);
      }
    }

    .title-float {
      color: $color-primary;
    }
  }
}

/* Input number */
.field__item {
  &.field--number {
    .p-inputnumber-input {
      width: 100%;
      height: 48px;
      padding: 16px 16px;
      background: transparent;
      font-size: 16px;
      font-weight: 600;
      border-radius: 8px;

      &:enabled:hover {
        border-color: $color-gray-3;
        box-shadow: none;

        & + .title-float {
          color: $color-gray-3;
        }
      }

      &:enabled:focus {
        border-color: $color-primary;
        box-shadow: 0 0 0 4px rgba(0, 219, 206, 0.35);

        &.ng-invalid.ng-dirty + .title-float {
          color: $color-primary;
        }
      }
    }

    p-inputnumber {
      &.p-inputwrapper-focus {
        & + label.title-float {
          color: $color-primary;
          font-size: 12px;
        }
      }
    }

    &.has-error {
      &.p-inputwrapper-focus.ng-invalid {
        & + label.title-float {
          color: $color-error;
        }
      }
    }
  }

  &.field--number__atribute {
    max-width: 132px;

    &.p-inputwrapper-focus {
      & + label.title-float {
        color: $color-primary;
        font-size: 12px;
      }
    }
  }
}

/* Field calendar */
.field__item.field--calendar {
  p-calendar.p-inputwrapper-focus {
    & + label.title-float {
      color: $color-primary;
    }
  }
}

/* Input dropdown */
.p-dropdown-trigger-icon {
  color: $color-primary;
}

/* Textarea */
textarea {
  min-height: 100px;
}

/* Input search */
.search-container {
  max-width: 289px;
  width: 289px;
  border-radius: 8px;
  overflow: hidden;

  .p-input-icon-left {
    width: 100%;
  }

  .p-input-icon-left > i:first-of-type {
    color: $color-gray-3;
    left: 16px;
  }

  .p-inputtext {
    width: 100%;
    font-size: 14px;
    color: $color-gray-4;
    line-height: 16px;
    border: none;
    border-radius: 8px;
    background-color: $color-gray-5;
    padding: 12px 16px;
    outline: none;

    &:enabled:hover {
      border: none;
    }

    &:enabled:focus {
      box-shadow: none;
    }
  }

  .p-input-icon-left > .p-inputtext {
    padding: 8px 16px 8px 48px;
  }
}

/* Input switch */
.p-inputswitch {
  height: 1.7rem !important;
  display: flex !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $color-primary !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
  background: $color-gray-6 !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: $color-gray-6 !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  box-shadow: none !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none !important;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.2 !important;
}

/* Input addon */
.p-inputgroup-addon {
  background: $color-gray-8 !important;
  color: $color-gray-1 !important;
  border: 1px solid $color-gray-3 !important;
  border-radius: 10px !important;
  margin-right: 5px !important;
}

/* Input date time */
.field--time {
  input[type="time"] {
    background: transparent;
    color: $color-primary;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid $color-gray-4;
    border-radius: 8px;
    padding: 5px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-align: center;

    &::-webkit-calendar-picker-indicator {
      filter: invert(100%);
      margin-right: 8px;
    }
  }
}

/* Field hour */
.field--hour {
  max-width: 125px;
  margin-bottom: 0;

  input {
    width: 100%;
    height: 40px;
    background: transparent;
    color: $color-gray-1;
    border-radius: 8px;
    border-color: $color-gray-4;
    cursor: pointer;
    box-shadow: none;

    /*&.ng-invalid.ng-dirty {
      border-color: $color-error;
      box-shadow: none;

      & + .title-float {
        color: $color-error;
      }
    }*/

    &:enabled:focus {
      border-color: $color-primary;
      box-shadow: 0 0 0 4px rgba(0, 219, 206, 0.35);

      &.ng-invalid.ng-dirty + .title-float {
        color: $color-primary;
      }
    }

    &:enabled:hover {
      border-color: $color-gray-3;
      box-shadow: none;

      & + .title-float {
        color: $color-gray-3;
      }
    }
  }
}

p-inputswitch {
  .p-inputswitch .p-inputswitch-slider {
    background: $color-gray-4;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.field__item--error {
  width: 100%;
  margin-top: 5px;
  font-size: 11px;
  color: $color-error;
  position: relative;
}
