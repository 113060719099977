.field--dropdown {
  p-dropdown {
    .p-dropdown-panel {
      top: 40px !important;
    }
  }

  &:focus {
    &.field--modal .p-float-label > .title-float {
      color: $color-primary;
    }
  }

  &:hover {
    &.field--modal .p-float-label > .title-float {
      color: $color-gray-3;
    }
  }
}

p-dropdown {
  .p-dropdown {
    width: 100%;
    background: $color-gray-5;
    color: $color-gray-1;
    border-radius: 10px;
    border: 1px solid $color-gray-4;

    &:not(.p-disabled):hover {
      border: 1px solid $color-gray-3;
      box-shadow: none;
    }
    &:not(.p-disabled).p-focus {
      border: 1px solid $color-gray-3;
      box-shadow: none;
    }
    .p-dropdown-panel
      .p-dropdown-items
      .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: $color-gray-3;
      background: $color-gray-6;
      outline: none;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      color: $color-gray-3;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      background: transparent;
    }
    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      font-size: 14px;
      color: $color-gray-4;
      line-height: 16px;
      border: none;
      border-radius: 8px;
      background-color: $color-gray-5;
      padding: 12px 16px;
      outline: none;
      box-shadow: none;
    }

    .p-dropdown-label {
      font-size: 14px;
      line-height: 34px;
      color: $color-gray-1;
    }
    .p-dropdown-clear-icon {
      display: none;
    }
  }

  &.dropdown--small {
    .p-dropdown {
      .p-dropdown-label {
        font-size: 14px;
        line-height: 16px;
        color: $color-gray-1;
        padding: 8px 16px;
      }
    }
  }

  &.p-inputwrapper-focus {
    & + .title-float {
      color: $color-primary;
    }
  }
}

/* Input calendar */
.field__item.field__calendar {
  p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: $color-error;
  }
  .p-float-label > .ng-invalid.ng-dirty + label {
    color: $color-error;
  }

  .p-inputtext:enabled:focus {
    border: 1px solid $color-gray-3;
    box-shadow: none;
  }

  .p-float-label input:focus ~ .title-float,
  .p-float-label input.p-filled ~ .title-float,
  .p-float-label textarea:focus ~ .title-float,
  .p-float-label textarea.p-filled ~ .title-float,
  .p-float-label .p-inputwrapper-focus ~ .title-float,
  .p-float-label .p-inputwrapper-filled ~ .title-float {
    top: -0.2rem;
    font-size: 12px;
    padding: 5px 7px;
    left: 12px;
  }
}

/* Input multiselect */
p-multiselect {
  &.p-multiselect {
    border: none;
    width: 100%;
    background: transparent;

    .p-multiselect {
      width: 100%;
      height: 48px;
      padding: 8px 16px;
      background: transparent;
      color: $color-gray-3;
      border-radius: 8px;
      border: 1px solid $color-gray-4;
      border-color: $color-gray-4;
      cursor: pointer;

      &:not(.p-disabled):hover {
        border: 1px solid $color-gray-3;
      }
      .p-multiselect-header .p-multiselect-close:enabled:hover {
        color: $color-gray-3;
        background: $color-gray-5;
      }
      .p-multiselect-header .p-multiselect-close:enabled:focus {
        box-shadow: none;
      }
    }
    .p-multiselect-panel.p-component {
      top: 37px !important;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
      color: $color-gray-3;
      background: $color-gray-6;
      box-shadow: none;
    }
  }
  .p-multiselect-trigger.ng-tns-c170-11 {
    margin-right: -16px;
    color: $color-primary;
  }
  .p-checkbox .p-checkbox-box {
    background: transparent;
    border: 1px solid $color-gray-3;
  }
}

.p-dropdown-panel.p-component,
.p-multiselect-panel.p-component {
  color: $color-gray-3;
  background: $color-gray-55;
  margin-top: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}
.p-multiselect-panel.p-component .p-multiselect-items .p-multiselect-item {
  color: $color-gray-3;
}

.p-dropdown-panel.p-component > .p-dropdown-header,
.p-multiselect-panel.p-component > .p-multiselect-header {
  color: $color-gray-3;
  background: $color-gray-55;
}

.p-multiselect-panel.p-component {
  .p-multiselect-items {
    .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
      color: $color-gray-3;
      background: $color-gray-6;
    }
  }
}

.field__item.field--dropdown {
  &.has-error {
    p-dropdown {
      .p-dropdown {
        border: 1px solid $color-error;

        .p-inputtext {
          border: none;
        }
        &:hover {
          border: 1px solid $color-gray-3;

          .p-inputtext {
            border: none;
          }
        }
      }
    }
    .p-float-label {
      margin-bottom: 27px;
    }
    &:hover {
      .p-dropdown {
        border: 1px solid $color-gray-3;
      }
    }

    .field__item--error {
      &::after {
        right: 36px;
      }
    }
  }

  .p-float-label input:focus ~ .title-float,
  .p-float-label input.p-filled ~ .title-float,
  .p-float-label textarea:focus ~ .title-float,
  .p-float-label textarea.p-filled ~ .title-float,
  .p-float-label .p-inputwrapper-focus ~ .title-float,
  .p-float-label .p-inputwrapper-filled ~ .title-float {
    top: -0.2rem;
    font-size: 12px;
    padding: 5px 7px;
    left: 12px;
  }
}
