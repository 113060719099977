/// IMPORTS
///
/// -> Core
/// - Main styles. Core content.
///
/// -> Helpers
/// - Collection of custom SASS Functions, Mixins, Animations and helper classes.
///   These files are included before the core since core makes use of some functions and mixins.
///
/// -> Modules
/// - Every modular/reusable element has its styles defined here.
///
/// -> Templates
/// - Every single file that hold styles for pages and sections.
///
/// @author UI BLACK iD Team
///

@import "core/core.scss";
@import "helpers/helpers.scss";
@import "modules/modules.scss";
@import "templates/templates.scss";
@import "general.scss";

// Better ways to organize CSS properties
/*
.class {
    // POSITION
    position: relative, absolute, fixed, sticky;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 0;

    // FLOAT
    float: right, left;

    // DISPLAY
    display: flex, block, none, inline;
    flex-direction, justify-content, align-items;

    // VISIBILITY
    visibility: hidden, visible;

    // WIDTH
    width: auto;
    max-width: auto;
    min-width: auto;

    // HEIGHT
    height: auto;
    max-height: auto;
    min-height: auto;

    // MARGIN
    margin: 0 auto;

    // PADDING  
    padding: 0 0;

    // BORDER
    border-box: none;
    outline: none;
    border: 1px solid rgba(0,0,0,0.1);

    // BACKGROUND 
    background-image: url();
    background-color: #000000;
    background-position: center center;  

    // BOX-SHADOW
    box-shadow: 0px 0px 0px rgba(0,0,0,0.1);
    
    // FONT
    font-family: Inter;
    font-size: 14px;
    font-weight: bold;
    
    // COLOR
    color: #000000;

    // TEXT
    line-height: 16px;
    letter-spacing: 1px;
    white-space: 1px;
    break-word: break-all;

    // OVERFLOW
    overflow: hidden;

    // OPACITY
    opacity: 0.5;
}
*/

// The project uses different UI frameworks, Bootstrap, PrimeNg and our custom theme.
// For more guides on Bootstrap: https://getbootstrap.com/docs/4.0/
// For more guides on PrimeNG: https://www.primefaces.org/primeng/showcase/#/
// For more information about SASS: https://sass-lang.com/documentation/
// For more information about BEM: http://getbem.com/introduction/