/*TOOLTIP GENERAL */
.tooltip-cursor {
  position: relative;
  cursor: pointer;

  &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: ' ';
      height: 20px;
      width: 20px;
      z-index: 9;
  }
}

.p-tooltip.p-tooltip-top .p-tooltip-text, .p-tooltip.p-tooltip-bottom .p-tooltip-text,
.p-tooltip.p-tooltip-left .p-tooltip-text, .p-tooltip.p-tooltip-right .p-tooltip-text {
    background-color: $color-gray-5 !important;
    color: $color-gray-1 !important;
    padding: 20px !important;
    box-shadow: none !important;
    border-radius: 6px !important;
}


/*TOOLTIP TOP*/
.p-tooltip.p-tooltip-top .p-tooltip-text {
    top: -12px;
    position: relative;
}

.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $color-gray-5 !important;
    bottom: -10px !important;
    left: 50% !important;
    margin-left: -1rem !important;
    border-width: 15px !important;
}

/*TOOLTIP BOTTOM*/

.p-tooltip.p-tooltip-bottom .p-tooltip-text {
    background-color: $color-gray-5 !important;
    color: #f3f3f3 !important;
    padding: 20px !important;
    box-shadow: none !important;
    border-radius: 6px !important;

    top: 5px;
    position: relative;
}

.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $color-gray-5 !important;
    top: 0 !important;
    left: 50% !important;
    margin-left: -1rem !important;
    border-width: 0 15px 15px !important;
}

/*TOOLTIP LEFT*/
.p-tooltip.p-tooltip.p-tooltip-left .p-tooltip-text {
    position: relative;
    left: -6px;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $color-gray-5 !important;
    top: 50%;
    right: 2px;
    margin-top: -1.25rem;
    border-width: 20px 0 20px 20px;
}

/*TOOLTIP RIGHT*/
.p-tooltip.p-tooltip.p-tooltip-right .p-tooltip-text {
    left: 8px;
    position: relative;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $color-gray-5 !important;
    top: 50%;
    left: 2px;
    margin-top: -1.2rem;
    border-width: 20px 20px 20px 0;
}
