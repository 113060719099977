@import "../core/colors.scss";

.fc-theme-standard td,
.fc-theme-standard th,
.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc {
  td.fc-daygrid-day {
    padding: 4px;
    height: 128px;

    .fc-daygrid-day-top {
      flex-direction: row;
    }

    .fc-daygrid-day-events {
      position: relative;
    }
    .fc-event-title {
      text-overflow: ellipsis;
    }

    .fc-daygrid-event {
      font-size: 12px;
      z-index: -1;
    }
  }

  .fc-toolbar .fc-toolbar-title {
    text-transform: uppercase;
    color: $color-gray-1;
    font-size: 18px;
    margin-top: 3px;
  }

  .fc-col-header-cell-cushion {
    color: $color-gray-3;
  }

  table {
    .fc-daygrid-day-number {
      color: $color-gray-3;
      padding: 15px 0 4px 15px;
      line-height: 14px;
    }

    .fc-daygrid-day-frame {
      max-height: 128px;
      min-height: 128px;
      max-width: 128px;
      min-width: 128px;
      transform: scale(1, 1);
      transition: all 200ms ease-in-out;
      background: $color-gray-55;
      border: 1px solid $color-gray-5;
      border-radius: 6px;

      &:hover {
        cursor: pointer;
        transform: scale(1.02, 1.02);
        transition: all 200ms ease-in-out;
        box-shadow: 0 14px 20px 0 rgb(0 0 0 / 75%) !important;
        z-index: 1;

        .fc-daygrid-day-top {
          .fc-daygrid-day-number {
            font-weight: 700 !important;
          }
        }
      }
    }

    .fc-daygrid-day.fc-day-today {
      background-color: transparent !important;
      border-radius: 6px;
      //box-shadow: 0px 14px 20px rgb(0 0 0 / 75%) !important;

      .fc-daygrid-day-frame {
        // border: 1px solid #10c9be !important;
        // box-shadow: 0px 14px 20px rgb(0 0 0 / 75%) !important;
        .fc-daygrid-day-top {
          .fc-daygrid-day-number {
            color: $color-primary !important;
          }
        }
      }
    }
    .fc-daygrid-day.fc-day-other {
      background: transparent;

      .fc-daygrid-day-frame {
        opacity: 0.5;
        &:hover {
          cursor: default !important;
          transform: none !important;
          box-shadow: none !important;
          z-index: 0 !important;
        }
      }
    }
    .fc-daygrid-day-bg .fc-day-today {
      background-color: transparent !important;
      border: 1px solid #10c9be !important;
      box-shadow: 0 14px 20px rgb(0 0 0 / 75%) !important;
      border-radius: 6px;
    }

    .fc-daygrid-day-bg .fc-highlight {
      border-radius: 6px !important;
      border: 1px solid #10c9be !important;
      box-shadow: 0 14px 20px rgb(0 0 0 / 75%) !important;
      background: none !important;
      z-index: 1;
      .fc-daygrid-day-frame {
        // border: 1px solid #10c9be !important;
        box-shadow: 0 14px 20px rgb(0 0 0 / 75%) !important;
        z-index: 1;
        .fc-daygrid-day-top {
          .fc-daygrid-day-number {
            font-weight: 700 !important;
          }
        }
      }
    }

    .fc-daygrid-day:has(.fc-highlight) {
      border-radius: 6px !important;
      border: 1px solid #10c9be !important;
      box-shadow: 0 14px 20px rgb(0 0 0 / 75%) !important;
      background: none !important;
      z-index: 1;
      .fc-daygrid-day-frame {
        // border: 1px solid #10c9be !important;
        box-shadow: 0 14px 20px rgb(0 0 0 / 75%) !important;
        z-index: 1;
        .fc-daygrid-day-top {
          .fc-daygrid-day-number {
            font-weight: 700 !important;
          }
        }
      }
    }

    .fc-daygrid-day.fc-day-past {
      background-color: transparent !important;

      .fc-daygrid-day-frame {
        background-color: $color-gray-6 !important;
        .fc-daygrid-day-top {
          opacity: 0.3;
        }
        .fc-daygrid-day-events {
          opacity: 0.3;
        }
      }
    }
  }
}

.btns-calendar {
  .p-button.p-button-icon-only {
    width: 24px;
    height: 24px;
    background-color: $color-gray-5;
    margin-right: 8px;
    border: 0;

    &:hover,
    &:active {
      background-color: $color-gray-contrast;
    }

    &:focus {
      box-shadow: none;
    }
    span {
      color: $color-primary;
    }
  }
}

/* Date picker */
.p-datepicker.p-component.ng-star-inserted {
  background-color: $color-gray-6;
  color: $color-gray-1;

  .p-datepicker-header {
    color: $color-gray-1;
    background-color: $color-gray-6;
    border-bottom: none;

    .p-datepicker-next:enabled:hover,
    .p-datepicker-next:enabled:focus {
      background-color: $color-gray-55;
      box-shadow: none;
    }
    .p-datepicker-prev:enabled:hover,
    .p-datepicker-prev:enabled:focus {
      background-color: $color-gray-55;
      box-shadow: none;
    }
  }

  table td > span:focus {
    background-color: $color-primary;
    color: $color-gray-5;
    box-shadow: none;
  }
  table td > span.p-highlight {
    background-color: $color-primary;
    color: $color-gray-5;
  }

  table td.p-datepicker-today > span {
    background-color: $color-primary;
    color: $color-gray-5;
  }
  &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background-color: $color-primary;
    color: $color-gray-5;
  }
}

// Calendar datepicker
.p-datepicker.p-component .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  box-shadow: none;
}
